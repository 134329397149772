import React, { useEffect, useState } from "react";
import "./styles.css";
//@ts-ignore
import line from "../../assets/hizmetler/line.png";
//@ts-ignore
import btUst from "../../assets/hizmetler/btUst.png";
//@ts-ignore
import webUst from "../../assets/hizmetler/webGelistirmeGiris.png";
//@ts-ignore
import mobilUst from "../../assets/hizmetler/mobilUst.png";
//@ts-ignore
import embededUst from "../../assets/hizmetler/embededUst.png";
//@ts-ignore
import devopsUst from "../../assets/hizmetler/devopsUst.png"
//@ts-ignore
import bulutUst from "../../assets/hizmetler/bulutUst.png";
//@ts-ignore
import dbUst from "../../assets/hizmetler/dbUst.png";
//@ts-ignore
import modernizationUst from "../../assets/hizmetler/modernizationUst.png";
//@ts-ignore
import iotUst from "../../assets/hizmetler/iotUst.png";
//@ts-ignore
import aiUst from "../../assets/hizmetler/aiUst.png";

import { Col, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { IHeader, getHeaderData } from "../../utils/getContents";
import { whichNavPagePhotoUst } from "../../utils/whichNavPage";
import { useWindowContext } from "../../context/WindowSizeContext";
import { useTranslation } from "react-i18next";

export default function Giris() {
	const location = useLocation();
	const page = location.pathname;

	const { mid, largeMid, mobile } = useWindowContext();
	const full = (!mid && !largeMid && !mobile)
	const small = (mid || mobile)

	const { t, i18n } = useTranslation()

	const projects = t('servicePages', { returnObjects: true });


	const [content, setContent] = useState<IHeader>(
		getHeaderData(page.split("/")[2], projects)
	);

	const [ustImg, setUstImg] = useState();

	useEffect(() => {
		setContent(getHeaderData(page.split("/")[2], projects));

		setUstImg(selectImg(page.split("/")[2]));
	}, [page, i18n.language]);

	useEffect(() => {
		console.log(ustImg);
	}, [ustImg]);

	const selectImg = (page: string) => {
		switch (page) {
			case "web-development":
				return webUst;
			case "embeded-software-development":
				return embededUst;
			case "mobile-applications":
				return mobilUst;
			case "iot-solutions":
				return iotUst;
			case "artificial-intelligence":
				return aiUst;

			case "dev-ops-ci-cd":
				return devopsUst;
			case "database-management":
				return dbUst;
			case "modernization-services":
				return modernizationUst;
			case "information-technology-consultancy":
				return btUst;
			case "cloud-migration":
				return bulutUst;

			default:
				return "";
		}
	};

	const fontStyle = {
		fontSize: small ? "20px" : "35px"
	}

	return (
		<section className='giris' style={{ marginTop: small ? "30px" : "80px" }}>
			<Row style={{ maxHeight: "fit-content" }}>
				{small && <div className='d-flex' style={{ marginLeft: (small) ? "30px" : "150px", width: "fit-content", marginBottom: "20px" }}>
					<img
						className='my-auto'
						src={line}
						height={1.5}
						width={small ? 30 : 70}
					/>
					<span className='heading-text' style={fontStyle}>{content?.title}</span>
				</div>}
				{small && (
					<Col lg={12} md={12} sm={12} style={{ textAlign: "center", display: "flex", alignItems: "center", height: "fit-content" }}>
						<img
							className='mx-auto'
							src={ustImg}
							width={"80%"}
							height={"80%"}
							style={{ objectFit: "cover", borderRadius: "10px" }}
						/>
					</Col>
				)}
				<Col lg={6} md={12} sm={12} style={{ height: "fit-content" }}>
					{!small && <div className='d-flex' style={{ marginLeft: (small) ? "30px" : "150px", }}>
						<img
							className='my-auto'
							src={line}
							height={1.5}
							width={small ? 30 : 70}
						/>
						<span className='heading-text' style={fontStyle}>{content?.title}</span>
					</div>}
					<p className='giris-text' style={{ paddingLeft: (small) ? "40px" : "150px", paddingRight: "45px", fontSize: small ? "15px" : "18px" }}>{content?.content}</p>
				</Col>
				{!small && (
					<Col style={{ textAlign: "left", maxHeight: "900px" }}>
						<img
							className='mx-auto'
							src={ustImg}
							width={"80%"}
							height={"82%"}
							style={{ objectFit: "cover", borderRadius: "10px" }}

						/>
					</Col>
				)}
			</Row>
		</section>
	);
}
