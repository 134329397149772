import React, { useEffect, useState } from "react";
//@ts-ignore
import line from "../../assets/anasayfa/Rectangle.png"; //@ts-ignore
import einsan from "../../assets/projelerimiz/einsan.png"; //@ts-ignore
import yetenekKapisi from "../../assets/projelerimiz/yetenekKapisi.png"; //@ts-ignore
import ilacTakip from "../../assets/projelerimiz/ilacTakip.png"; //@ts-ignore
import asistan from "../../assets/projelerimiz/asistan.png"; //@ts-ignore
import kepan from "../../assets/projelerimiz/kepan.png";
import DynamicHeader from "../../components/header/DynamicHeader";
import HizmetlerNavigation from "../../components/HizmetlerNavigation";
import { useLocation, useNavigate } from "react-router-dom";
import CaseStudies from "./CaseStudies";
import LeftAligned from "./LeftAligned";
import RightAligned from "./RightAligned";
import Iletisim from "../../components/iletişim/Iletisim";
import Footer from "../../components/footer/Footer";
import { useWindowContext } from "../../context/WindowSizeContext";
import { useTranslation } from "react-i18next";

export default function Projelerimiz() {
	const location = useLocation();
	const page = location.pathname;

	const navigate = useNavigate();

	const { mid, mobile, largeMid, width } = useWindowContext();
	const full = !largeMid && !mid && !mobile;
	const small = mid || mobile;

	const { t } = useTranslation()

	const [active, setActive] = useState("0");

	const handleClick = (id: string) => {
		if (id === "1") {
			setActive("1");
			document.getElementById("0")?.classList.remove("active");
			document.getElementById("1")?.classList.add("active");
		} else {
			setActive("0");
			document.getElementById("1")?.classList.remove("active");
			document.getElementById("0")?.classList.add("active");
		}
		navigate("/projects");
	};

	const fontStyle = {
		fontSize: small ? "14px" : "20px",
	};

	return (
		<div className='projelerimiz'>
			<DynamicHeader />
			<div
				className='nav-hizmetler mx-auto'
				style={{
					padding: small ? "0 20px 0 20px" : "",
					justifyContent: small ? "space-between" : "space-around",
					marginTop: "30px",
					width: "70%",
				}}
			>
				<>
					<span
						id='0'
						className='nav-item active'
						title='dev-ops-ci-cd'
						onClick={(e) => handleClick("0")}
						style={fontStyle}
					>
						{t('navProjects')}
					</span>
					<span
						id='1'
						className='nav-item'
						title='gomulu-yazilim-gelistirme'
						onClick={(e) => handleClick("1")}
						style={fontStyle}
					>
						{t('navCaseStudies')}
					</span>
				</>
			</div>

			{active === "0" ? (
				<div>
					<div
						className='heading-container'
						style={{
							marginLeft: small ? "40px" : "100px",
							marginTop: small ? "40px" : "80px",
						}}
					>
						<img
							className='mt-auto heading-container-image'
							src={line}
							height={1.5}
							width={70}
						/>
						<h3
							className='about-heading'
							style={{
								fontSize: small
									? "20px"
									: largeMid
										? "25px"
										: "35px",
							}}
						>
							{t('navProjects')}
						</h3>
					</div>
					{small ? (
						<>
							<div style={{ marginTop: "40px" }} />
							<LeftAligned
								number='01'
								title={t('project1Title')}
								text={t('project1Text1')}
								img={yetenekKapisi}
							/>
							<LeftAligned
								number='02'
								title={t('project2Title')}
								text={t('project2Text1')}
								img={einsan}
								subtext1={t('project2Text2')}
							/>
							<LeftAligned
								number='03'
								title={t('project3Title')}
								text={t('project3Text1')}
								img={ilacTakip}
								subtext1={t('project3Text2')}
								subtext2={t('project3Text3')}
							/>
							<LeftAligned
								number='04'
								title={t('project4Title')}
								text={t('project4Text1')}
								img={asistan}
								subtext1={t('project4Text2')}
							/>
							<LeftAligned
								number='05'
								title={t('project5Title')}
								text={t('project5Text1')}
								img={kepan}
								subtext1={t('project5Text2')}
								subtext2={t('project5Text3')}
							/>
							<div style={{ paddingBottom: "60px" }} />
						</>
					) : (
						<>
							<div style={{ marginTop: "40px" }} />
							<LeftAligned
								number='01'
								title={t('project1Title')}
								text={t('project1Text1')}
								img={yetenekKapisi}
							/>
							<RightAligned
								number='02'
								title={t('project2Title')}
								text={t('project2Text1')}
								img={einsan}
								subtext1={t('project2Text2')}
							/>
							<LeftAligned
								number='03'
								title={t('project3Title')}
								text={t('project3Text1')}
								img={ilacTakip}
								subtext1={t('project3Text2')}
								subtext2={t('project3Text3')}
							/>
							<RightAligned
								number='04'
								title={t('project4Title')}
								text={t('project4Text1')}
								img={asistan}
								subtext1={t('project4Text2')}
							/>
							<LeftAligned
								number='05'
								title={t('project5Title')}
								text={t('project5Text1')}
								img={kepan}
								subtext1={t('project5Text2')}
								subtext2={t('project5Text3')}
							/>
							<div style={{ paddingBottom: "60px" }} />
						</>
					)}
				</div>
			) : (
				<CaseStudies />
			)}
			<Iletisim />
			<Footer />
		</div>
	);
}
