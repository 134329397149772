import React, { useEffect, useState } from 'react'
import "./styles.css"
//@ts-ignore
import icon1 from "../../assets/hizmetler/ozellikIcon1.png"
//@ts-ignore
import icon2 from "../../assets/hizmetler/ozellikIcon2.png"
//@ts-ignore
import icon3 from "../../assets/hizmetler/ozellikIcon3.png"
import { useLocation } from 'react-router-dom'
import { IOzellikler, getHeaderData, getOzelliklerData } from '../../utils/getContents'
import { useWindowContext } from '../../context/WindowSizeContext'
import { useTranslation } from 'react-i18next'



export default function Ozellikler() {

  const location = useLocation()
  const page = location.pathname

  const { t, i18n } = useTranslation()

  const projects = t('servicePages', { returnObjects: true })

  const { mid, largeMid, mobile } = useWindowContext()
  const full = (!mid && !largeMid && !mobile)
  const small = (mid || mobile)

  const [content, setContent] = useState<IOzellikler>(getOzelliklerData(page.split("/")[2], projects))

  useEffect(() => {
    setContent(getOzelliklerData(page.split("/")[2], projects))
  }, [page, i18n.language])

  const ozellikStyle = {
    marginLeft: small ? "20px" : "80px"
  }

  const headerFont = {
    fontSize: small ? "20px" : "35px",
    lineHeight: small ? "40px" : "75px",
  }

  const contentFont = {
    fontSize: small ? "15px" : "18px",
    lineHeight: small ? "30px" : "38px",

  }

  return (
    <div className='ozellikler' style={{ padding: small ? "40px" : "150px 260px 100px 240px" }}>
      <div className='bg-ozellikler' />

      <div className='ozellik-item'>
        {<img src={icon1} height={small ? 45 : 125} style={{ marginTop: small ? "10px" : "" }} />}
        <div className='ozellik-text' style={ozellikStyle}>
          <span className='ozellik-text-header' style={{ color: "rgba(0, 158, 211, 1)", ...headerFont }}>{content.o1.title}</span>
          <span className='ozellik-text-content' style={contentFont}>{content.o1.content}</span>
        </div>
      </div>
      <div className='ozellik-item'>
        {content.o2?.content && <img src={icon2} height={small ? 38 : 125} style={{ marginTop: small ? "10px" : "" }} />}
        <div className='ozellik-text' style={ozellikStyle}>
          <span className='ozellik-text-header' style={{ color: "rgba(0, 158, 211, 1)", ...headerFont }}>{content.o2?.title}</span>
          <span className='ozellik-text-content' style={contentFont}>{content.o2?.content}</span>
        </div>
      </div>
      <div className='ozellik-item'>
        {content.o3?.content && <img src={icon3} height={small ? 38 : 125} style={{ marginTop: small ? "10px" : "" }} />}
        <div className='ozellik-text' style={ozellikStyle}>
          <span className='ozellik-text-header' style={{ color: "rgba(0, 158, 211, 1)", ...headerFont }}>{content.o3?.title}</span>
          <span className='ozellik-text-content' style={contentFont}>{content.o3?.content}</span>
        </div>
      </div>
    </div>
  )
}
