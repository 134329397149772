import React, { createContext, useContext, useEffect, useState } from 'react'
import useWindowSize from '../utils/useWindowSize'


interface IProps {
    children: React.ReactNode
}

export type GlobalContext = {
    mobile: boolean
    mid: boolean
    largeMid:boolean
    setMobile: (v: boolean) => void
    width:number
}

export const WindowSizeContext = createContext<GlobalContext>({
    mobile: false,
    mid: false,
    largeMid:false,
    setMobile: (_value: boolean) => { },
    width:0
})

const WindowSizeProvider = (props: IProps) => {
    const [mobile, setMobile] = useState<boolean>(false)
    const [mid, setMid] = useState<boolean>(false)
    const [largeMid,setLargeMid] = useState<boolean>(false)
    
    const [width] = useWindowSize()

    useEffect(() => {
        
        

        if(width<1200 && width >= 994) {
            setLargeMid(true)
            setMid(false)
            setMobile(false)
        }
        else if (width <= 994 && width > 650){
            setLargeMid(false)
            setMid(true)
            setMobile(false)
        }
        else if (width <= 650) {
            setMobile(true)
            setMid(false)
            setLargeMid(false)
        }
        else {
            setMobile(false)
            setMid(false)
            setLargeMid(false)
        }
        
    }, [width])
    return (
        <WindowSizeContext.Provider value={{ largeMid,mid, mobile, setMobile,width }}>
            {props.children}
        </WindowSizeContext.Provider>
    )
}

const useWindowContext = () => useContext(WindowSizeContext)

export { useWindowContext, WindowSizeProvider }