import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import iplocation from "iplocation"; // IP lokasyon paketi
import translationEn from "./locales/eng/translations.json";
import translationTR from "./locales/tr/translations.json";
import translationDe from "./locales/de/translations.json";
import translationNl from "./locales/nl/translations.json";

export const resources = {
    eng: {
        translation: translationEn,
    },
    tr: {
        translation: translationTR,
    },
    de: {
        translation: translationDe
    },
    nl: {
        translation: translationNl
    }
};

// Desteklenen diller
const supportedLanguages = ['eng', 'tr', 'de', 'nl'];

// LocalStorage'da dil var mı kontrol et
let storedLanguage = localStorage.getItem("I18N_LANGUAGE");

interface IPLocation {
    country: {
        code: string;
    };
}

// IP adresine göre dil belirleme işlemi
const determineLanguage = async (): Promise<string> => {
    if (!storedLanguage) {
        try {
            const response = await fetch('https://api.ipify.org?format=json');
            const data = await response.json();
            const ip = data.ip;
            const location: IPLocation = await iplocation(ip) as IPLocation;
            const countryCode = location.country.code.toLowerCase();
            const languageMap: { [key: string]: string } = {
                'tr': 'tr',
                'de': 'de',
                'nl': 'nl'
            };
            storedLanguage = languageMap[countryCode] || 'eng';
            localStorage.setItem("I18N_LANGUAGE", storedLanguage);
        } catch (error) {
            console.error("IP lokasyon alınamadı, varsayılan dil eng olarak ayarlandı:", error);
            storedLanguage = 'eng';
            localStorage.setItem("I18N_LANGUAGE", storedLanguage);
        }
    }
    return storedLanguage;
};

// Dil belirleme işlemi tamamlandıktan sonra i18next'i başlat
determineLanguage().then((lng) => {
    i18n
        .use(initReactI18next) // passes i18n down to react-i18next
        .init({
            resources,
            lng: lng,
            fallbackLng: "eng", // use eng if detected lng is not available

            keySeparator: false, // we do not use keys in form messages.welcome

            interpolation: {
                escapeValue: false, // react already safes from xss
            },
        });

    // Dil değiştirildiğinde localStorage'ı güncelle
    i18n.on('languageChanged', (lng) => {
        localStorage.setItem("I18N_LANGUAGE", lng);
    });
});

export default i18n;
