import React from 'react'
import DynamicHeader from '../../components/header/DynamicHeader'
import HizmetlerNavigation from '../../components/HizmetlerNavigation'
import Giris from '../../components/hizmetler/Giris'
import Ozellikler from '../../components/hizmetler/Ozellikler'
import HizmetKapsamı from '../../components/hizmetler/HizmetKapsamı'
import DigerHizmetler from "../../components/hizmetler/DigerHizmetler"
import Iletisim from '../../components/iletişim/Iletisim'
import Footer from '../../components/footer/Footer'
import { useWindowContext } from '../../context/WindowSizeContext'

export default function ButunHizmetSayfalari() {

  const { mid, mobile, largeMid, width } = useWindowContext();
	const small = mid || mobile;

  return (
    <div className='iot-gelistirme'>
        <DynamicHeader/>
        {!small&&<HizmetlerNavigation/>}
        <Giris/>
        <Ozellikler/>
        <HizmetKapsamı/>
        <DigerHizmetler/>
        <Iletisim/>
        <Footer/>
    </div>
  )
}
