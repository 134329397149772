import React from 'react'
import { Col, Row } from 'react-bootstrap'
//@ts-ignore
import line from "../../assets/anasayfa/Rectangle.png"//@ts-ignore
import ai from "../../assets/anasayfa/icons/ai.png"//@ts-ignore
import db from "../../assets/anasayfa/icons/db.png"//@ts-ignore
import devOps from "../../assets/anasayfa/icons/devOps.png"//@ts-ignore
import gomulu from "../../assets/anasayfa/icons/gomulu.png"//@ts-ignore
import iot from "../../assets/anasayfa/icons/iot.png"//@ts-ignore
import modernizasyon from "../../assets/anasayfa/icons/modernizasyon.png"//@ts-ignore
import mobil from "../../assets/anasayfa/icons/mobil.png"//@ts-ignore
import webGelistirme from "../../assets/anasayfa/icons/webGelistirme.png"//@ts-ignore
import BTDanismanligi from "../../assets/anasayfa/icons/BTDanismanligi.png"//@ts-ignore
import BulutaTasima from "../../assets/anasayfa/icons/BulutaTasima.png"
import { useNavigate } from 'react-router-dom'
import { useWindowContext } from '../../context/WindowSizeContext'
import { useTranslation } from 'react-i18next'

export default function DigerHizmetler() {

    const { mid, largeMid, mobile } = useWindowContext();
    const full = (!mid && !largeMid && !mobile)
    const small = (mid || mobile)

    const navigate = useNavigate()

    const { t } = useTranslation()

    const goTo = (e: React.MouseEvent<HTMLDivElement>, page: string) => {
        e.preventDefault()
        navigate("/services/" + page)
        window.scrollTo(0, 0)
    }

    const headerFont = {
        fontSize: largeMid ? "30px" : small ? "25px" : "50px",
        lineHeight: small ? "40px" : "75px",
        marginLeft: small ? "40px" : "100px"

    }

    const cardStyle = {
        marginLeft: small ? "15px" : "20px",
        minWidth: full || largeMid ? "378px" : "200px",
        height: "fit-content"
    }

    const rowStyle = {
        padding: small ? "40px 0 40px 0" : "100px 0px 100px 0px",
        margin: small ? "0 40px 40px 40px" : "0 100px 100px 100px",
    }

    const cardIconStyle = {
        display: "flex",
        justifyContent: "center",
        height: small ? "60px" : "120px",
        padding: small ? "10px" : "20px",
        minHeight: small ? "60px" : "120px"
    }

    const cardTitle = {
        fontSize: small ? "16px" : "26px"
    }

    const cardContent = {
        fontSize: small ? "12px" : "18px"
    }

    return (
        <div className='diger-hizmetler'>
            <p className='diger-hizmetler-title' style={headerFont} >{t('otherServices')}</p>
            <div className='diger-hizmetler-row' style={rowStyle}>

                <div className='card card-custom card-hizmetler' style={cardStyle} onClick={(e) => goTo(e, "web-development")}>
                    <div className='card-icon' style={cardIconStyle}>
                        <img src={webGelistirme} height={small ? 40 : 80} />
                    </div>
                    <div className='card-title my-2' >
                        <h1 style={cardTitle}>{t('homeServicesTech1Title').split(' ')[0]} <br /> {t('homeServicesTech1Title').split(' ')[1]}</h1>
                    </div>
                    <div className='card-hizmetler-content' >
                        <h2 style={cardContent}>{t('homeServicesTech1Content')}</h2>
                    </div>
                </div>



                <div className='card card-custom card-hizmetler' style={cardStyle} onClick={(e) => goTo(e, "embeded-software-development")}>
                    <div className='card-icon' style={cardIconStyle}>
                        <img src={gomulu} height={small ? 40 : 80} />
                    </div>
                    <div className='card-title my-2'>
                        <h1 style={cardTitle}>{t('homeServicesTech2Title').split(' ')[0]} <br /> {t('homeServicesTech2Title').split(' ')[1]}</h1>
                    </div>
                    <div className='card-hizmetler-content'>
                        <h2 style={cardContent}>{t('homeServicesTech2Content')}</h2>
                    </div>

                </div>

                <div className='card card-custom card-hizmetler' style={cardStyle} onClick={(e) => goTo(e, "mobile-applications")}>
                    <div className='card-icon' style={cardIconStyle}>
                        <img src={mobil} height={small ? 40 : 80} />
                    </div>
                    <div className='card-title my-2'>
                        <h1 style={cardTitle}>{t('homeServicesTech3Title').split(' ')[0]}  <br /> {t('homeServicesTech3Title').split(' ')[1]}</h1>
                    </div>
                    <div className='card-hizmetler-content'>
                        <h2 style={cardContent}>{t('homeServicesTech3Content')}</h2>
                    </div>
                </div>


                <div className='card card-custom card-hizmetler' style={cardStyle} onClick={(e) => goTo(e, "iot-solutions")}>
                    <div className='card-icon' style={cardIconStyle}>
                        <img src={iot} height={small ? 40 : 80} />
                    </div>
                    <div className='card-title my-2'>
                        <h1 style={cardTitle}>{t('homeServicesTech4Title')}</h1>
                    </div>
                    <div className='card-hizmetler-content'>
                        <h2 style={cardContent}>{t('homeServicesTech5Content')}</h2>
                    </div>
                </div>

                <div className='card card-custom card-hizmetler' style={cardStyle} onClick={(e) => goTo(e, "artificial-intelligence")}>
                    <div className='card-icon' style={cardIconStyle}>
                        <img src={ai} height={small ? 40 : 80} />
                    </div>
                    <div className='card-title my-2'>
                        <h1 style={cardTitle}>{t('homeServicesTech5Title')}</h1>
                    </div>
                    <div className='card-hizmetler-content'>
                        <h2 style={cardContent}>{t('homeServicesTech5Content')}</h2>
                    </div>
                </div>

                <div className='card card-custom card-hizmetler' style={cardStyle} onClick={(e) => goTo(e, "dev-ops-ci-cd")}>
                    <div className='card-icon' style={cardIconStyle}>
                        <img src={devOps} height={small ? 40 : 80} />
                    </div>
                    <div className='card-title my-2'>
                        <h1 style={cardTitle}>{t('homeServicesTech6Title')}</h1>
                    </div>
                    <div className='card-hizmetler-content'>
                        <h2 style={cardContent}>{t('homeServicesTech6Content')}</h2>
                    </div>
                </div>

                <div className='card card-custom card-hizmetler' style={cardStyle} onClick={(e) => goTo(e, "database-management")}>
                    <div className='card-icon' style={cardIconStyle}>
                        <img src={db} height={small ? 40 : 80} />
                    </div>
                    <div className='card-title my-2'>
                        <h1 style={cardTitle}>{t('homeServicesTech7Title')}</h1>
                    </div>
                    <div className='card-hizmetler-content'>
                        <h2 style={cardContent}>{t('homeServicesTech7Content')}</h2>
                    </div>
                </div>

                <div className='card card-custom card-hizmetler' style={cardStyle} onClick={(e) => goTo(e, "modernization-services")}>
                    <div className='card-icon' style={cardIconStyle}>
                        <img src={modernizasyon} height={small ? 40 : 80} />
                    </div>
                    <div className='card-title my-2'>
                        <h1 style={cardTitle}>{t('homeServicesTech8Title')}</h1>
                    </div>
                    <div className='card-hizmetler-content'>
                        <h2 style={cardContent}>{t('homeServicesTech8Content')}</h2>
                    </div>
                </div>

                <div className='card card-custom card-hizmetler' style={cardStyle} onClick={(e) => goTo(e, "information-technology-consultancy")}>
                    <div className='card-icon' style={cardIconStyle}>
                        <img src={BTDanismanligi} height={small ? 40 : 80} />
                    </div>
                    <div className='card-title my-2'>
                        <h1 style={cardTitle}>{t('homeServicesTech9Title')}</h1>
                    </div>
                    <div className='card-hizmetler-content'>
                        <h2 style={cardContent}>{t('homeServicesTech9Content')}</h2>
                    </div>
                </div>

                <div className='card card-custom card-hizmetler' style={cardStyle} onClick={(e) => goTo(e, "cloud-migration")} >
                    <div className='card-icon' style={cardIconStyle}>
                        <img src={BulutaTasima} height={small ? 40 : 80} />
                    </div>
                    <div className='card-title my-2'>
                        <h1 style={cardTitle}>{t('homeServicesTech10Title')}</h1>
                    </div>
                    <div className='card-hizmetler-content'>
                        <h2 style={cardContent}>{t('homeServicesTech10Content')}</h2>
                    </div>
                </div>

            </div>

        </div>
    )
}
