import React from 'react'
//@ts-ignore
import line from "../../assets/anasayfa/Rectangle.png"
import DynamicHeader from '../../components/header/DynamicHeader'
import HizmetlerNavigation from '../../components/HizmetlerNavigation'
import { Col, Row } from 'react-bootstrap'
import { Cases } from "../../data/CaseStudies"
import Case from './Case'
import { useWindowContext } from '../../context/WindowSizeContext'
import { t } from 'i18next'


interface ICaseStudie {
  title: string
  text: string
  features: string[]
}

export default function CaseStudies() {

  const { mid, mobile, largeMid, width } = useWindowContext();
  const full = !largeMid && !mid && !mobile;
  const small = mid || mobile;



  const caseStudies: ICaseStudie[] = t('caseStudies', { returnObjects: true });

  return (
    <div>
      <div className='heading-container' style={{ marginLeft: (mid || mobile) ? "40px" : "150px", marginTop: small ? "40px" : "80px" }}>
        <img className='mt-auto heading-container-image' src={line} height={1.5} width={70} />
        <h3 className='about-heading' style={{ fontSize: (mid || mobile) ? "20px" : "35px" }}>{t('navCaseStudies')}</h3>
      </div>
      <Row className='case-row' style={{ marginLeft: "50px", marginRight: "50px" }}>
        {caseStudies.map((val, i) => {
          return (
            <Col className='case-col' xl={4} lg={6} md={12} sm={12} style={{ padding: "50px 50px 30px 50px", display: "flex", justifyContent: "center" }}>
              <Case title={val.title} text={val.text} ozellikler={val.features} />
            </Col>
          )
        })}
      </Row>
    </div>
  )
}
