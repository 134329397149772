import React, { RefObject, useEffect, useRef, useState } from "react";
import "./styles.css";
import { useLocation, useNavigate } from "react-router-dom";
import { whichNavPageId, whichNavPageRouteName } from "../utils/whichNavPage";
//@ts-ignore
import vector from "../assets/hizmetler/forwardVector.png";//@ts-ignore
import backVector from "../assets/hizmetler/backVector.png";
import { useTranslation } from "react-i18next";

interface IHizmetlerNavProps {
	isMain?: boolean;
}

export default function HizmetlerNavigation({ isMain }: IHizmetlerNavProps) {
	const navigate = useNavigate();

	const location = useLocation();
	const page = location.pathname;

	const { t } = useTranslation()

	const [activeItem, setActiveItem] = useState(
		whichNavPageId(page.split("/")[2])
	);
	const [prevActiveItem, setPrevActiveItem] = useState(
		whichNavPageId("")
	);

	const [splited, setSplited] = useState([
		whichNavPageRouteName(page.split("/")[2]),
		undefined,
	]);

	const [whichPage, setWhichPage] = useState<string>(page.split("/")[1]);

	useEffect(() => {
		const active = document.getElementById(
			whichNavPageId(page.split("/")[2]).toString()
		);
		if (active) active.classList.add("active");
	}, []);

	useEffect(() => {
		if (page.split("/")[2] !== "") {
			setSplited([whichNavPageRouteName(page.split("/")[2]), undefined]);

			setPrevActiveItem(activeItem);
			setTimeout(() => {
				setActiveItem(whichNavPageId(page.split("/")[2]));
			}, 100);

		} else {
			setActiveItem(0);
		}
	}, [page]);

	useEffect(() => {
		document.getElementById(activeItem.toString())?.classList.add("active")
		document.getElementById(prevActiveItem.toString())?.classList.remove("active")
	}, [activeItem])

	useEffect(() => {
		if (splited[1] === 0) {
			const activate = document.getElementById("0");
			activate?.classList.add("active");
			if (activeItem >= 5) {
				setActiveItem(0);
				// setPrevActiveItem(0);
			} else if (activeItem <= 4) {
				setActiveItem(5);
				// setPrevActiveItem(5);
			}
			setTimeout(() => {
				navigate("/services/web-development");
			}, 100);
		} else if (splited[1] === 1) {
			const activate = document.getElementById("5");
			activate?.classList.add("active");
			setActiveItem(5);
			// setPrevActiveItem(5);

			setTimeout(() => {
				navigate("/services/dev-ops-ci-cd");
			}, 100);
		}
	}, [splited]);

	const handleClick = (
		e: React.MouseEvent<HTMLSpanElement>,
		targetPage: string
	) => {
		const clicked = document.getElementById(e.currentTarget.id);
		const prevClicked = document.getElementById(activeItem.toString());

		if (clicked && prevClicked) {
			if (
				!(
					(prevActiveItem < 5 && clicked.id >= "5") ||
					(prevActiveItem > 4 && clicked.id <= "4")
				)
			) {
				prevClicked!.classList.remove("active");
			} else {
			}
			clicked.classList.add("active");
			navigate("/" + page.split("/")[1] + "/" + targetPage);
		}
		setPrevActiveItem(activeItem);
		setActiveItem(Number.parseInt(e.currentTarget.id));
	};

	return (
		<div
			className='nav-hizmetler mx-auto '
			style={{
				justifyContent: !isMain ? "space-between" : "space-around",
				marginTop: isMain ? "0" : "30px",
				width: isMain ? "70%" : "80%",
				paddingTop: isMain ? "0px" : "30px",
			}}
		>
			{(splited[0] === 0 || splited[1] === 0) && !isMain && (
				<>
					<span
						id='0'
						className='nav-item'
						title='web-development'
						onClick={(e) => handleClick(e, "web-development")}
					>
						{t('navServicesTech1')}
					</span>
					<span
						id='1'
						className='nav-item'
						title='embeded-software-development '
						onClick={(e) =>
							handleClick(e, "embeded-software-development")
						}
					>
						{t('navServicesTech2')}
					</span>
					<span
						id='2'
						className='nav-item'
						title='mobile-applications'
						onClick={(e) => handleClick(e, "mobile-applications")}
					>
						{t('navServicesTech3')}
					</span>
					<span
						id='3'
						className='nav-item'
						title='iot-solutions'
						onClick={(e) => handleClick(e, "iot-solutions")}
					>
						{" "}
						{t('navServicesTech4')}
					</span>
					<span
						id='4'
						className='nav-item'
						title='artificial-intelligence'
						onClick={(e) =>
							handleClick(e, "artificial-intelligence")
						}
					>
						{t('navServicesTech5')}
					</span>
					<div
						className='d-flex'
						style={{ cursor: "pointer" }}
						onClick={() =>
							setSplited(
								splited[0] === 0 || splited[1] === 0
									? [undefined, 1]
									: [undefined, 0]
							)
						}
					>
						<img
							className='my-auto'
							src={vector}
							width={7}
							height={13}
						/>
						<img
							className='my-auto'
							src={vector}
							width={7}
							height={13}
						/>
					</div>
				</>
			)}

			{(splited[0] === 1 || splited[1] === 1) && !isMain && (
				<>
					<div
						className='d-flex'
						style={{ cursor: "pointer" }}
						onClick={() =>
							setSplited(
								splited[0] === 0 || splited[1] === 0
									? [undefined, 1]
									: [undefined, 0]
							)
						}
					>
						<img
							className='my-auto'
							src={backVector}
							width={7}
							height={13}
						/>
						<img
							className='my-auto'
							src={backVector}
							width={7}
							height={13}
						/>
					</div>
					<span
						id='5'
						className='nav-item'
						title='dev-ops-ci-cd'
						onClick={(e) => handleClick(e, "dev-ops-ci-cd")}
					>
						{t('navServicesTech6')}
					</span>
					<span
						id='6'
						className='nav-item'
						title='database-management '
						onClick={(e) => handleClick(e, "database-management")}
					>
						{t('navServicesTech7')}
					</span>
					<span
						id='7'
						className='nav-item'
						title='modernization-services'
						onClick={(e) =>
							handleClick(e, "modernization-services")
						}
					>
						{t('navServicesTech8')}
					</span>
					<span
						id='8'
						className='nav-item'
						title='information-technology-consultancy'
						onClick={(e) =>
							handleClick(e, "information-technology-consultancy")
						}
					>
						{" "}
						{t('navServicesTech9')}
					</span>
					<span
						id='9'
						className='nav-item'
						title='cloud-migration'
						onClick={(e) => handleClick(e, "cloud-migration")}
					>
						{t('navServicesTech10')}
					</span>
				</>
			)}

			{splited[0] !== 2 && isMain && whichPage === "services" && (
				<>
					<span
						id='0'
						className='nav-item active'
						title='dev-ops-ci-cd'
						onClick={(e) => navigate("/services")}
					>
						{t('navServices')}
					</span>
					<span
						id='10'
						className='nav-item'
						title='gomulu-yazilim-gelistirme'
						onClick={(e) => handleClick(e, "developed-services")}
					>
						{t('developedServices')}
					</span>
				</>
			)}
			{splited[0] == 2 && isMain && whichPage === "services" && (
				<>
					<span
						id='0'
						className='nav-item'
						title='dev-ops-ci-cd'
						style={{ color: "#fff" }}
						onClick={(e) => navigate("/services")}
					>
						{t('navServices')}
					</span>
					<span
						id='10'
						className='nav-item active'
						title='gomulu-yazilim-gelistirme'
						onClick={(e) => handleClick(e, "developed-services")}
					>
						{t('developedServices')}
					</span>
				</>
			)}
		</div>
	);
}
