import { TFunction } from "i18next"

export const getPageName = (page: string, t: TFunction<"translation", undefined>): string => {

    switch (page) {
        case "projects": return t("navProjects")
        case "how-we-do": return t("navHowWeDo")
        case "about-us": return t("navAboutUs")
        case "blog": return "Blog"
        case "contact": return t("navContact")
        case "blog/123": return "Eski Sistemden Buluta:Göç Stratejileri"
        default: return ""
    }

}