import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Nav, NavDropdown, Navbar, NavbarCollapseProps, Row } from "react-bootstrap";
//@ts-ignore
import logo from "../../assets/logo.png";
//@ts-ignore
import neokodDarkLogo from "../../assets/neokodDarkLogo.png";
import { useNavigate } from "react-router-dom";
import { useWindowContext } from "../../context/WindowSizeContext";
//@ts-ignore
import dropdownPhoto from "../../assets/dropdownPhoto.png";
//@ts-ignore
import dropdownVector from "../../assets/dropdownVector.png";
import "./styles.css";
import { useTranslation } from "react-i18next";
import { parseLangString } from "../../utils/capitalize"

interface NavProps {
	setIsOpen: (val: boolean) => void;
	setDropdownOpen?: (val: boolean) => void
}

export default function TestNavbar(props: NavProps) {

	const [isCollapsed, setIsCollapsed] = useState(false);
	const navigate = useNavigate();

	const { t, i18n } = useTranslation()

	const changeLanguage = (lng: string) => {
		i18n.changeLanguage(lng);

	}

	const { mid, largeMid, mobile } = useWindowContext();
	const full = (!mid && !largeMid && !mobile)
	const small = mid || mobile

	const collapseRef = useRef<HTMLDivElement>(null)

	const handleToggle = () => {

		setIsCollapsed(!isCollapsed)
	}

	const [drOpen, setDrOpen] = useState(false)

	const handleDrOpen = () => {
		setDrOpen(!drOpen)
	}

	const openRef = useRef(null)



	useEffect(() => {

		props.setIsOpen(isCollapsed)

	}, [isCollapsed])



	const fontStyle = {
		fontSize: (!full) ? "15px" : "20px"
	}

	return (
		<Navbar expand='lg' className='bg-body-tertiary test-navbar mx-auto' style={{ paddingTop: (mobile || mid) ? "50px" : "110px" }}>
			<Container fluid>
				<Navbar.Brand onClick={() => navigate("/")}>
					<img
						src={((mobile || mid) && isCollapsed) ? neokodDarkLogo : logo}
						width={!full ? 185 : 275}
						height={!full ? 53 : 80}
						style={{ cursor: "pointer" }}
						onClick={() => navigate("/")}
					/>
				</Navbar.Brand>
				<Navbar.Toggle
					className='custom-navbar-toggle'
					aria-controls='basic-navbar-nav'
					onClick={handleToggle}
					as={"div"}
					style={{ color: isCollapsed ? "#040404" : "#fff" }}
				>{isCollapsed ? (<i className="fa-solid fa-xmark" />) : (<i className="fa-solid fa-bars" />)}</Navbar.Toggle>
				<Navbar.Collapse id='basic-navbar-nav' ref={collapseRef}>
					<Nav className={"custom-collapse-nav ms-auto" + (((mobile || mid) && isCollapsed) ? " collapsed-color" : "")} style={{ width: (small && isCollapsed) ? "100%" : "", border: (small && isCollapsed) ? "1px solid #e1f1fa" : "", backgroundColor: (small && isCollapsed) ? "#fff" : "", position: (small && isCollapsed) ? "absolute" : "relative", zIndex: "100", paddingTop: (mobile || mid) ? "20px" : "" }} >
						<Nav.Link className='my-auto nav-li mx-2' onClick={() => navigate("/")} style={fontStyle}>
							{t('navHome')}
						</Nav.Link>

						<NavDropdown
							title={t('navServices')}
							bsPrefix='nav-li dropdown-nav-li mx-2'
							className=' nav-li my-auto me-auto'
							style={{ position: "relative", color: "#fff", paddingLeft: 0, padding: "15px 0 15px  0", ...fontStyle, width: small ? "100%" : "", textAlign: "left" }}
							onSelect={handleDrOpen}

						>
							<div ref={openRef}>
								<Row className='dr-container nav-li my-auto  mx-3' style={{ width: full ? "700px" : "fit-content" }}>
									<Col lg={6}>
										<NavDropdown.Item
											className='dr-item'
											onClick={() => navigate('/services/web-development')}

										>
											<span className='dot'>•</span>{t('navServicesTech1')}
										</NavDropdown.Item>
										<NavDropdown.Item
											className='dr-item'
											onClick={() => navigate('/services/embeded-software-development')}
										>
											<span className='dot'>•</span>{t('navServicesTech2')}
										</NavDropdown.Item>
										<NavDropdown.Item
											className='dr-item'
											onClick={() => navigate('/services/mobile-applications')}
										>
											<span className='dot'>•</span>{t('navServicesTech3')}
										</NavDropdown.Item>
										<NavDropdown.Item
											className='dr-item'
											onClick={() => navigate('/services/iot-solutions')}
										>
											<span className='dot'>•</span>{t('navServicesTech4')}
										</NavDropdown.Item>
										<NavDropdown.Item
											className='dr-item'
											onClick={() => navigate('/services/artificial-intelligence')}
										>
											<span className='dot'>•</span>{t('navServicesTech5')}
										</NavDropdown.Item>
										<NavDropdown.Item
											className='dr-item'
											onClick={() => navigate('/services/dev-ops-ci-cd')}
										>
											<span className='dot'>•</span>{t('navServicesTech6')}
										</NavDropdown.Item>
										<NavDropdown.Item
											className='dr-item'
											onClick={() => navigate('/services/database-management')}
										>
											<span className='dot'>•</span>
											{t('navServicesTech7')}
										</NavDropdown.Item>
										<NavDropdown.Item
											className='dr-item'
											onClick={() => navigate('/services/modernization-services')}
										>
											<span className='dot'>•</span>
											{t('navServicesTech8')}
										</NavDropdown.Item>
										<NavDropdown.Item
											className='dr-item'
											onClick={() => navigate('/services/information-technology-consultancy')}
										>
											<span className='dot'>•</span>{t('navServicesTech9')}
										</NavDropdown.Item>
										<NavDropdown.Item
											className='dr-item'
											onClick={() => navigate('/services/cloud-migration')}
										>
											<span className='dot'>•</span>
											{t('navServicesTech10')}
										</NavDropdown.Item>
										<NavDropdown.Item
											className='dr-item'
											onClick={() => navigate('/services')}
										>
											<span className='dot'>•</span>
											{t('developedServices')}
										</NavDropdown.Item>
									</Col>
									{full && (
										<Col className='d-flex' lg={5}>
											<img
												src={dropdownVector}
												height={"100%"}
												width={1}
											/>
											<img
												src={dropdownPhoto}
												width={220}
												height={170}
												style={{
													marginTop: "60px",
													marginLeft: "30px",
												}}
											/>
										</Col>
									)}
								</Row>
							</div>

						</NavDropdown>
						<Nav.Link
							className='my-auto mx-2 nav-li'
							onClick={() => navigate('/projects')}
							style={fontStyle}
						>
							{t('navProjects')}
						</Nav.Link>
						<Nav.Link
							className='my-auto mx-2 nav-li'
							onClick={() => navigate('/how-we-do')}
							style={fontStyle}
						>
							{t('navHowWeDo')}
						</Nav.Link>
						<Nav.Link
							className='my-auto mx-2 nav-li'
							onClick={() => navigate('/about-us')}
							style={fontStyle}
						>
							{t('navAboutUs')}
						</Nav.Link>
						{/* <Nav.Link className='my-auto mx-2 nav-li' href="/blog">Blog</Nav.Link> */}
						<Nav.Link
							className='my-auto mx-2 nav-li'
							onClick={() => navigate('/contact')}
							style={fontStyle}
						>
							{t('navContact')}
						</Nav.Link>
						<NavDropdown
							title={parseLangString(i18n.language).toUpperCase()}
							bsPrefix='nav-li dropdown-nav-li mx-2'
							className=' nav-li my-auto me-auto'
							style={{ position: "relative", color: "#fff", paddingLeft: 0, padding: "15px 0 15px  0", ...fontStyle, width: small ? "100%" : "", textAlign: "left" }}
							onSelect={handleDrOpen}

						>
							<NavDropdown.Item
								className='dr-item'
								onClick={() => changeLanguage("eng")}
							>
								<span className='dot'>•</span>English
							</NavDropdown.Item>
							<NavDropdown.Item
								className='dr-item'
								onClick={() => changeLanguage("tr")}
							>
								<span className='dot'>•</span>Türkçe
							</NavDropdown.Item>
							<NavDropdown.Item
								className='dr-item'
								onClick={() => changeLanguage("de")}
							>
								<span className='dot'>•</span>Deutsch
							</NavDropdown.Item>
							<NavDropdown.Item
								className='dr-item'
								onClick={() => changeLanguage("nl")}
							>
								<span className='dot'>•</span>Nederlands
							</NavDropdown.Item>
						</NavDropdown>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}
