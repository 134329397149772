import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./styles.css";
//@ts-ignore
import logo from "../../assets/footer/logoFooter.png";
//@ts-ignore
import lineVertical from "../../assets/footer/lineVertical.png";
//@ts-ignore
import lineHorizontal from "../../assets/footer/lineHorizontal.png";
//@ts-ignore
import instaLogo from "../../assets/footer/logo-instagram.png";
//@ts-ignore
import twitterLogo from "../../assets/footer/logo-twitter.png";
//@ts-ignore
import linkedinnLogo from "../../assets/footer/logo-linkedinn.png";


import MoreBtn from "../MoreBtn";
import { useWindowContext } from "../../context/WindowSizeContext";
import { useTranslation } from "react-i18next";

export default function Footer() {
	const { mid, mobile, largeMid, width } = useWindowContext();
	const full = !largeMid && !mid && !mobile;
	const small = mid || mobile
	const navigate = useNavigate();

	const { t } = useTranslation()

	return (
		<footer>
			<Row>
				<Col
					xl={5}
					lg={12}
					md={12}
					sm={12}
					className='footer-first-col'
					style={{ paddingLeft: full || largeMid ? "100px" : "15%", display: "flex", justifyContent: "center", alignItems: "center" }}
				>
					<div
						className={'left-col-container'}

					>
						<img
							style={{
								marginBottom: !mobile ? "40px" : "10px",

							}}
							src={logo}
							width={full ? 340 : 180}
							height={full ? 100 : 50}
						/>
						{!full && (
							<div style={{ marginTop: "30px" }}>
								<img
									style={{ width: "90%" }}
									src={lineHorizontal}
								/>
							</div>
						)}
						<br />
						{/* <p
							className={'footer-txt'+mobile?("mt-3"):""}
							style={{
								maxWidth: full ? "" : mobile ? "90%" : "60%",
							}}
						>
							En son haberlerimiz, ürünlerimiz, promosyonlarımız
							ve etkinliklerimiz hakkında içeriden bilgi almak
							için abone olun.
						</p> */}
						<br />

						{/* <div className='mail-form '>
							<form
								action='mailto:info@neokodyazilim.com'
								style={{ width: "100%" }}
							>
								<label className='mb-2'>E-Posta</label>
								<div
									style={{
										position: "relative",

										height: "fit-content",
										width: full
											? "80%"
											: mobile
											? "90%"
											: "60%",
									}}
								>
									<input
										className='form-control mail-input'
										name='subject'
									/>

									<Button
										className="more-btn"
										style={{
											position: "absolute",
											top: "0px",
											right: "0px",
											borderRadius: "40px",
											height: "100%",
											width: "40%",
											border: "1px solid #1f72c0"
										}}
									>
										Gönder
									</Button>
								</div>
							</form>
						</div> */}
					</div>
				</Col>
				<Col xl={7} lg={12} md={12} sm={12} className='d-flex'>
					{full ? <img src={lineVertical} height={541} /> : ""}
					<div className='right-col-container' style={{ margin: small ? "20px 2rem 0 2rem" : "100px 80px 0 80px" }}>
						<div
							className='pages-container'
							style={{
								paddingLeft: mid ? "0%" : mobile ? "1.5rem" : "",
								display: width < 480 ? "block" : "flex",
								fontSize: (mid || mobile) ? "12px" : "20px",

							}}
						>
							<div style={{ textAlign: "center" }}>
								<p
									className='page-item'
									onClick={() => navigate("/")}
									style={{ fontSize: (mid || mobile) ? "16px" : "20px" }}
								>
									{t('navHome')}
								</p>
								<p
									className='page-item'
									onClick={() => navigate("/services")}
									style={{ fontSize: (mid || mobile) ? "16px" : "20px" }}
								>
									{t('navServices')}
								</p>
								<p
									className='page-item'
									onClick={() => navigate("/projects")}
									style={{ fontSize: (mid || mobile) ? "16px" : "20px" }}
								>
									{t('navProjects')}
								</p>
								<p
									className='page-item'
									onClick={() => navigate("/how-we-do")}
									style={{ fontSize: (mid || mobile) ? "16px" : "20px" }}
								>
									{t('navHowWeDo')}
								</p>
							</div>
							<div
								style={{
									marginLeft:
										width < 480
											? "0px"
											: mid || mobile
												? "16%"
												: "24px",
								}}
							>
								<p
									className='page-item'
									onClick={() => navigate("/about-us")}
									style={{ fontSize: (mid || mobile) ? "16px" : "20px" }}
								>
									{t('navAboutUs')}
								</p>
								{/* <p
									className='page-item'
									onClick={() => navigate("/blog")}
									style={{ fontSize: (mid || mobile) ? "16px" : "20px" }}
								>
									Blog
								</p> */}
								<p
									className='page-item'
									onClick={() => navigate("/contact")}
									style={{ fontSize: (mid || mobile) ? "16px" : "20px" }}
								>
									{t('navContact')}
								</p>
							</div>
						</div>
						{!(mid || mobile) ? (
							<p className='company-name'>Neokod</p>
						) : (
							""
						)}
						<div
							className='my-3'
							style={{
								display: "flex",
								justifyContent: "center",
								width: "100%",
							}}
						>
							{!full && (
								<img
									style={{ width: "100%", marginTop: "10px" }}
									src={lineHorizontal}
								/>
							)}
						</div>

						<div
							className='row pt-2'
							style={{
								paddingLeft: mid
									? "10%"
									: mobile || mid
										? "1.5rem"
										: "",
								width: "100%",
							}}
						>
							<div
								className='address col col-md-4 col-sm-4'
								style={{
									paddingLeft: mobile || mid ? 0 : "12px",
									marginLeft:
										width > 480
											? "0"
											: mid || mobile
												? "0"
												: "60px",
									marginRight: !full ? "10px" : "",
									marginTop: width < 480 ? "20px" : "",
								}}
							>
								<span style={{ fontWeight: "600", fontSize: (mid || mobile) ? "18px" : "22px" }}>
									{t('footerAddressRnDTitle')}
								</span>
								<br />
								<span style={{ fontSize: (mid || mobile) ? "16px" : "20px" }}>{t('footerAddressRnDAddress').split(' ').map((x, i) => i < 3 ? x + " " : "")}</span>
								<span style={{ fontSize: (mid || mobile) ? "16px" : "20px" }}>{t('footerAddressRnDAddress').split(' ').map((x, i) => i > 2 ? x + " " : "")}</span>
							</div>
							<div
								className='address col'
								style={{
									paddingLeft: mobile ? 0 : "12px",
									marginLeft:
										width > 480
											? "60px"
											: mid || mobile
												? "20px"
												: "60px",
									marginTop: width < 480 ? "20px" : "",
									marginRight: !full ? "15px" : "",
								}}
							>
								<span style={{ fontSize: (mid || mobile) ? "16px" : "20px", fontWeight: "600" }}>
									{t('footerAddressOfficeTitle')}
								</span>
								<br />
								<span style={{ fontSize: (mid || mobile) ? "16px" : "20px" }}>{t('footerAddressOfficeAddress').split(' ').map((x, i) => i < 4 ? x + " " : "")}</span>
								<span style={{ fontSize: (mid || mobile) ? "16px" : "20px" }}>{t('footerAddressOfficeAddress').split(' ').map((x, i) => i > 3 ? x + " " : "")}</span>

							</div>
							<div
								className='my-3'
								style={{
									display: "flex",
									justifyContent: "center",
									width: "100%",
								}}
							>

							</div>




						</div>
						{!full && (
							<div style={{ height: "20px", width: "100%", textAlign: "center", padding: 0 }}>
								<img
									style={{ width: "100%" }}
									src={lineHorizontal}
								/>
							</div>
						)}
						<div
							className='d-flex col'
							style={{
								width: "100%",
								marginLeft: full ? "80px" : "0",
								justifyContent: "center",
								marginTop: "40px",
							}}
						>

							<a className='social-media'>
								<img
									className='mx-3'
									src={twitterLogo}
									width={25}
									height={25}
								/>
							</a>
							<a className='social-media'>
								<img
									className='mx-3'
									src={linkedinnLogo}
									width={25}
									height={25}
								/>
							</a>

						</div>
						<p
							className="footer-mail-p"
							style={{
								marginLeft: full ? "60px" : !mobile ? "60px" : "0",
								marginTop: mid || mobile ? "20px" : "50px",

							}}
						>
							info@neokodyazilim.com
						</p>
					</div>
				</Col>
				{full ? (
					<img className='horizontal-line' src={lineHorizontal} />
				) : (
					""
				)}
			</Row>
		</footer>
	);
}
