import React from 'react'
import { Col } from 'react-bootstrap'
import { useWindowContext } from '../../context/WindowSizeContext'

interface IOzellikProp {
    text: string
}

interface ICaseProp {
    title: string
    text: string
    ozellikler: string[]
}
export default function Case(props: ICaseProp) {

    const Ozellik: React.FC<IOzellikProp> = ({ text }: IOzellikProp) => {
        return (
            small ? (
                <div className='case-ozellik mx-1' style={{ width: text.length > 7 ? (text.length > 14 ? ("160px") : ("105px")) : "40px", minWidth: text.length > 7 ? (text.length > 14 ? ("120px") : ("90px")) : "50px", height: "35px" }}>
                    <p className='mt-3' style={{ fontSize: small ? "10px" : "12px" }}>{text}</p>
                </div>
            ) : (
                <div className='case-ozellik mx-1' style={{ width: text.length > 7 ? (text.length > 14 ? ("330px") : ("270px")) : "100px" }}>
                    <p className='mt-3' style={{ fontSize: small ? "10px" : "12px" }}>{text}</p>
                </div>
            )

        )
    }

    const { mid, mobile, largeMid, width } = useWindowContext();
    const full = !largeMid && !mid && !mobile;
    const small = mid || mobile;

    return (
        <div className='case-card'
            style={{
                width: small ? "450px" : "530px",
                height: largeMid ? "540px" : small ? "460px" : width < 1400 ? "670px" : "570px",
                maxHeight: mid ? "550" : "",
                padding: small ? "20px" : "40px",
                borderRadius: "15px",
                marginBottom: "40px",
                objectFit: "cover",
            }}>
            <p className='case-title' style={{ fontSize: small ? "18px" : "24px", lineHeight: small ? "28px" : full ? "36px" : "34px" }}>{props.title}</p>
            <p className='case-text mt-1' style={{ fontSize: small ? "14px" : "16px", lineHeight: small ? "21px" : full ? "30px" : "24px", maxHeight: full ? "230px" : "" }}>{props.text}</p>
            <div className='ozellik-area row' style={{ bottom: small ? "20px" : "40px", width: small ? "80%" : "" }}>
                {props.ozellikler.map((ozellik, index) => {
                    return (
                        <>
                            {props.ozellikler.length > 4 ? (
                                <Col lg={ozellik.length > 14 ? 6 : 4} md={ozellik.length > 8 ? 6 : 4} sm={6} xs={6} style={{ display: "flex", justifyContent: "center", paddingRight: 0, paddingLeft: 0 }}>
                                    <Ozellik key={index} text={ozellik} />
                                </Col>
                            ) : (
                                <Col lg={6} md={6} sm={4} xs={6} style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80px" }}>
                                    <Ozellik key={index} text={ozellik} />
                                </Col>
                            )}

                        </>

                    )
                })}
            </div>

        </div>
    )
}
