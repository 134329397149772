import React from "react";
import "./index.css";
import MoreBtn from "../MoreBtn";
import { useNavigate } from "react-router-dom";
import { useWindowContext } from "../../context/WindowSizeContext";
import { useTranslation } from "react-i18next";

export default function Iletisim() {
	const { mobile, mid, largeMid } = useWindowContext();
	const full = !mobile && !mid && !largeMid;

	const navigate = useNavigate();

	const { t } = useTranslation()

	return (
		<div
			className='iletisim-component'
			style={{ height: full || largeMid ? "600px" : "450px" }}
		>
			<div
				className='iletisim-text-container mx-auto'
				style={{
					padding: full
						? "150px 0 0 0"
						: mid || mobile
							? "80px 40px 80px 40px"
							: "",
					maxWidth: full ? "1100px" : largeMid ? "100" : "",
				}}
			>
				<div
					className="iletisim-span-div"
					style={{
						fontSize: full
							? "50px"
							: largeMid
								? "40px"
								: mobile
									? "20px"
									: "30px",
						lineHeight: full
							? "60px"
							: largeMid
								? "48px"
								: mobile
									? "30px"
									: "40px",
					}}
				>
					<span className='big-text'>
						{t('contactFooterTitle').split(' ').map((x, i) => i < 5 ? x + " " : " ")}
						{!(full || largeMid) &&
							t('contactFooterTitle').split(' ').map((x, i) => i > 4 ? x + " " : " ")}
					</span>
					{(full || largeMid) && (
						<>
							<br />
							<span className='big-text'>
								{t('contactFooterTitle').split(' ').map((x, i) => i > 4 ? x + " " : " ")}
							</span>
						</>
					)}
				</div>

				{full && <br />}
				<br />
				<div
					className="iletisim-span-div"
					style={{
						fontSize: full
							? "23px"
							: largeMid
								? "18px"
								: mobile
									? "14px"
									: "14px",
						lineHeight: full
							? "29px"
							: largeMid
								? "16px"
								: mobile
									? "18px"
									: "18px",

					}}
				>
					<span className='small-text'>
						{t('contactFooterSubtitle').split(' ').map((x, i) => i < 7 ? x + " " : "")}
					</span>
					<br />
					<span className='small-text'>{t('contactFooterSubtitle').split(' ').map((x, i) => i > 6 ? x + " " : "")}</span>
				</div>
				<br />
				<div className="d-flex">
					<MoreBtn
						className='mt-4 mx-auto'
						onclick={() => navigate("/contact")}
						child={t('contactUsButton')}
					/>
				</div>

			</div>
		</div>
	);
}
