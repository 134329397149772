import React, { useEffect } from "react";
import "./styles.css";
//@ts-ignore
import line from "../../assets/anasayfa/Rectangle.png";
//@ts-ignore
import aboutUs from "../../assets/anasayfa/aboutUs.png";
import { Col, Row } from "react-bootstrap";
import MoreBtn from "../../components/MoreBtn";
import { useNavigate } from "react-router-dom";
import { useWindowContext } from "../../context/WindowSizeContext";

import { useTranslation } from "react-i18next"

export default function AboutUs() {
	const navigate = useNavigate();

	const { t } = useTranslation()

	const { mid, mobile, largeMid, width } = useWindowContext();
	const full = !largeMid && !mid && !mobile;

	return (
		<section className='about-us' style={{ padding: (full || largeMid) ? "120px 150px 120px 150px" : mid ? "80px 60px 80px 60px" : "30px" }}>
			<Row
				className='about-row'
				style={{
					paddingTop: largeMid ? "100px" : "150px",
					padding: !(mobile || mid)
						? ""
						: "50px 25px 50px 25px",
					marginBottom: width < 1600 ? "30px" : ""
				}}
			>
				<Col
					xl={6}
					lg={12}
					md={12}
					sm={12}
					style={{ position: "relative", }}
				>
					<div className='heading-container'>
						<img
							className='mt-auto heading-container-image'
							src={line}
							height={1.5}
							width={largeMid ? 50 : 70}
							style={{ marginBottom: !full ? "30px" : "" }}
						/>
						<h3
							className='about-heading'
							style={{
								marginBottom: !full ? "30px" : "",
								fontSize: mobile ? "25px" : "35px",
							}}
						>
							{t('aboutUs')}
						</h3>
					</div>
					{!full && (
						<img
							className='mx-auto'
							src={aboutUs}
							style={{
								display: "block",
								height: "300px",
								objectFit: "cover",
								width: "85%",
								borderRadius: "10px",
							}}
						/>
					)}
					<div
						className='text-container'
						style={{
							paddingRight:
								full ? "5%" : "",
							paddingLeft: full ? "50px" : "",
							marginLeft: full ? "30px" : "",
						}}
					>
						<p
							className='text-header-span text-header'
							style={{
								fontSize: !full ? "25px" : "35px",
								lineHeight: !full ? "32px" : "46px",

							}}
						>
							{t('aboutUsTitle')}
							<br />
							<p
								className='text-p'
								style={{
									marginTop: !full ? "10px" : "",
									fontSize: !full ? "16px" : "21px",
									lineHeight: !full ? "28px" : "36px",
								}}
							>
								{t('aboutUsContent')}
							</p>
						</p>
					</div>
					{!largeMid && !mid && !mobile ? (
						<MoreBtn
							child={t('moreButton')}
							styles={{

								marginLeft: full ? "80px" : "",

							}}
							onclick={() => navigate("/about-us")}
						/>
					) : (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<MoreBtn
								child={t('moreButton')}
								styles={{
									marginTop: "30px",
								}}
								onclick={() => navigate("/about-us")}
							/>
						</div>
					)}
				</Col>
				<Col
					xl={6}
					lg={12}
					md={12}
					sm={12}
					style={{ textAlign: "right" }}
				>
					{full && (
						<img
							className='mx-auto'
							src={aboutUs}
							style={{ display: "block" }}
						/>
					)}
				</Col>
			</Row>
		</section>
	);
}
