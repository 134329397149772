import { useEffect, useLayoutEffect, useState } from 'react';
import { debounce } from 'lodash';

const useWindowSize = () => {

    const [size, setSize] = useState([0, 0]);

    useLayoutEffect(() => {
        const updateSize = debounce(() => {
            setSize([window.innerWidth, window.innerHeight]);

        }, 10); // 100ms debounce süresi
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return size;
}

export default useWindowSize