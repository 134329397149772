import React from "react";
import "./styles.css";
import { useWindowContext } from "../context/WindowSizeContext";

interface IBtnProps {
	child: React.ReactNode;
	styles?: React.CSSProperties;
	className?: string;
	onclick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
	id?: string;
}

export default function MoreBtn(props: IBtnProps) {

	const { mobile, mid, largeMid } = useWindowContext()
	const full = !mobile && !mid && !largeMid;

	return (
		<button
			className={props.className + " btn btn-lg more-btn"}
			style={{ ...props.styles, width: (full || largeMid) ? "232px" : "175px", height: (full || largeMid) ? "65px" : "55px", fontSize: (full || largeMid) ? "20px" : "16px" }}
			onClick={props.onclick}
		>
			{props.child}
		</button>
	);
}
