import React, { RefObject, useEffect, useRef, useState } from "react";
import "./styles.css";
import { useNavigate } from "react-router-dom";
//@ts-ignore
import line from "../../assets/anasayfa/Rectangle.png";
//@ts-ignore
import ai from "../../assets/anasayfa/icons/ai.png";
//@ts-ignore
import db from "../../assets/anasayfa/icons/db.png";
//@ts-ignore
import devOps from "../../assets/anasayfa/icons/devOps.png";
//@ts-ignore
import gomulu from "../../assets/anasayfa/icons/gomulu.png";
//@ts-ignore
import iot from "../../assets/anasayfa/icons/iot.png";
//@ts-ignore
import modernizasyon from "../../assets/anasayfa/icons/modernizasyon.png";
//@ts-ignore
import mobil from "../../assets/anasayfa/icons/mobil.png";
//@ts-ignore
import webGelistirme from "../../assets/anasayfa/icons/webGelistirme.png";
//@ts-ignore
import BTDanismanligi from "../../assets/anasayfa/icons/BTDanismanligi.png";
//@ts-ignore
import BulutaTasima from "../../assets/anasayfa/icons/BulutaTasima.png";
import { Col, Row } from "react-bootstrap";
import MoreBtn from "../../components/MoreBtn";
import { useWindowContext } from "../../context/WindowSizeContext";
import { useTranslation } from "react-i18next";

export interface IHizmeterimizProps {
	isForMain?: boolean;
}

export default function Hizmetlerimiz({ isForMain }: IHizmeterimizProps) {
	const { mid, mobile, largeMid, width } = useWindowContext();
	const full = !largeMid && !mid && !mobile;
	const small = mid || mobile;

	const { t } = useTranslation()

	const navigate = useNavigate();


	const [selected, setSelected] = useState(1);

	const cardRefs = useRef<(HTMLDivElement | null)[]>([]);


	const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
		e.preventDefault();

		const clickedCard = document.getElementById(e.currentTarget.id);
		const prevCard = document.getElementById(selected.toString());

		if (clickedCard && prevCard) {
			prevCard.classList.remove("active");
			clickedCard.classList.add("active");
			if (e.currentTarget.id !== "1") {
				const firstCard = cardRefs.current[0]
				firstCard?.classList.remove("active")

				// classList.remove("active")
			}
			else {
				const firstCard = cardRefs.current[0]
				firstCard?.classList.add("active")
			}
		}

		setSelected(Number.parseInt(e.currentTarget.id));
	}

	const goTo = (e: React.MouseEvent<HTMLButtonElement>, page: string) => {
		e.preventDefault();
		navigate("/services/" + page);
		window.scrollTo(0, 0);
	};

	const redirectToMain = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		navigate("/services");
		window.scrollTo(0, 0);
	};

	useEffect(() => {
		cardRefs.current.forEach((ref, index) => {
			if (ref) {
				ref.classList.add('example-class');
				if (index === 0) ref.classList.add("active")
			}
		});
	}, []);

	// Dinamik olarak referansları ayarlayan fonksiyon
	const setRef = (index: number) => (el: HTMLDivElement | null) => {
		cardRefs.current[index] = el;
	};



	const customCardStyle = {
		height: mobile ? "480px" : "520px",
		// width: mobile ? "264px" : "378px",
	};

	const h1Font = {
		fontSize: mobile ? "18px" : "26px",
	}

	const h2Font = {
		fontSize: mobile ? "14px" : "18px"
	}

	const cardContentStyle = {
		paddingLeft: mobile ? "0" : "20px",
		paddingRight: mobile ? "0" : "20px",
		paddingTop: mobile ? "0px" : "20px"
	}

	return (
		<section
			className='hizmetlerimiz'
			style={{
				paddingLeft: mobile ? "20px" : mid ? "60px" : "150px",
				paddingRight: mobile ? "20px" : mid ? "60px" : "150px",
			}}
		>
			<div className='hizmetlerimiz-container' style={{ paddingBottom: small ? "0" : "40px" }}>
				<div
					className='heading-container'
					style={{ paddingTop: small ? "30px" : "50px", marginLeft: full ? "100px" : "", marginTop: small ? "40px" : "80px" }}
				>
					<img
						className='mt-auto heading-container-image'
						src={line}
						height={1.5}
						width={70}
					/>
					<h3 className='about-heading' style={{ fontSize: mobile ? "25px" : "35px" }}>{t('services')}</h3>
				</div>
			</div>
			<div
				className='text-container'
				style={{
					padding:
						width > 1400 ? "30px 150px 60px 200px" : "20px 0 40px 0",
					paddingLeft: mobile ? "10px" : (full || largeMid) ? "200px" : ""
				}}
			>
				<div
					className='text-header'
					style={{}}
				>
					<h5 className='hizmetlerimiz-h5' style={{ width: "100%", fontSize: mobile ? "16px" : "25px", lineHeight: mobile ? "35px" : "40px", paddingRight: mobile ? "0px" : "80px", }}>
						{t('servicesTitle')}
					</h5>
				</div>
			</div>
			<div className='teknolojiler-container'>
				<Row>
					<Col
						className='my-2'
						xl={width < 1800 ? 4 : 3}
						lg={6}
						mid={12}
						sm={12}
						xs={12}
						style={{
							width:
								width > 1600
									? "25%"
									: width < 1600 && width > 1200
										? "33%"
										: largeMid || mid
											? "50%"
											: mobile
												? "100%"
												: "100%",
						}}
					>
						<div
							className='card card-custom  mx-auto'
							ref={setRef(0)}
							onClick={handleClick}
							id='1'
							style={customCardStyle}
						>
							<div className='card-icon d-flex' >
								<img className="mx-auto my-auto" src={webGelistirme} height={mobile ? "60px" : "80px"} />
							</div>
							<div className='card-title my-2' >
								<h1 style={h1Font}>
									{t('homeServicesTech1Title').split(' ')[0]} <br /> {t('homeServicesTech1Title').split(' ')[1]}
								</h1>
							</div>
							<div className='card-content' style={cardContentStyle}>
								<h2 style={h2Font} >
									{t('homeServicesTech1Content')}
								</h2>
							</div>
							{selected === 1 && (
								<MoreBtn
									className='mx-auto my-auto'
									child={t('moreButton')}
									onclick={(e) => goTo(e, "web-development")}
								/>
							)}
						</div>
					</Col>
					<Col
						className='my-2'
						xl={width < 1800 ? 4 : 3}
						lg={6}
						mid={12}
						sm={12}
						xs={12}
						style={{
							width:
								width > 1600
									? "25%"
									: width < 1600 && width > 1200
										? "33%"
										: largeMid || mid
											? "50%"
											: "100%",
						}}
					>
						<div
							className='card card-custom mx-auto'
							ref={setRef(1)}
							onClick={handleClick}
							id='2'
							style={customCardStyle}
						>
							<div className='card-icon d-flex' >
								<img className="mx-auto my-auto" src={gomulu} width={mobile ? "60px" : "82px"} height={mobile ? "60px" : "80px"} />
							</div>
							<div className='card-title my-2'>
								<h1 style={h1Font}>
									{t('homeServicesTech2Title').split(' ')[0]} <br /> {t('homeServicesTech2Title').split(' ')[1]}
								</h1>
							</div>
							<div className='card-content' style={cardContentStyle}>
								<h2 style={h2Font} >
									{t('homeServicesTech2Content')}
								</h2>
							</div>
							{selected === 2 && (
								<MoreBtn
									className='mx-auto my-auto'
									child={t('moreButton')}
									onclick={(e) =>
										goTo(e, "embeded-software-development")
									}
								/>
							)}
						</div>
					</Col>
					<Col
						className='my-2'
						xl={3}
						lg={6}
						mid={12}
						sm={12}
						xs={12}
						style={{
							width:
								width > 1600
									? "25%"
									: width < 1600 && width > 1200
										? "33%"
										: largeMid || mid
											? "50%"
											: "100%",
						}}
					>
						<div
							className='card card-custom mx-auto'
							ref={setRef(2)}
							onClick={handleClick}
							id='3'
							style={customCardStyle}
						>
							<div className='card-icon d-flex' >
								<img className="mx-auto my-auto" src={mobil} width={mobile ? "60px" : "82px"} height={mobile ? "60px" : "80px"} />
							</div>
							<div className='card-title my-2'>
								<h1 style={h1Font}>
									{t('homeServicesTech3Title').split(' ')[0]} <br /> {t('homeServicesTech3Title').split(' ')[1]}
								</h1>
							</div>
							<div className='card-content' style={cardContentStyle}>
								<h2 style={h2Font}>
									{t('homeServicesTech3Content')}
								</h2>
							</div>

							{selected === 3 && (
								<MoreBtn
									className='mx-auto my-auto'
									child={t('moreButton')}
									onclick={(e) =>
										goTo(e, "mobile-applications")
									}
								/>
							)}
						</div>
					</Col>
					<Col
						className='my-2'
						xl={3}
						lg={6}
						mid={12}
						sm={12}
						xs={12}
						style={{
							width:
								width > 1600
									? "25%"
									: width < 1600 && width > 1200
										? "33%"
										: largeMid || mid
											? "50%"
											: "100%",
						}}
					>
						<div
							className='card card-custom mx-auto'
							ref={setRef(3)}
							onClick={handleClick}
							id='4'
							style={customCardStyle}
						>
							<div className='card-icon d-flex' >
								<img className="mx-auto my-auto" src={iot} width={mobile ? "60px" : "82px"} height={mobile ? "60px" : "80px"} />
							</div>
							<div className='card-title my-2'>
								<h1 style={h1Font}>{t('homeServicesTech4Title')}</h1>
							</div>
							<div className='card-content' style={cardContentStyle}>
								<h2 style={h2Font}>
									{t('homeServicesTech4Content')}
								</h2>
							</div>
							{selected === 4 && (
								<MoreBtn
									className='mx-auto my-auto'
									child={t('moreButton')}
									onclick={(e) => goTo(e, "iot-solutions")}
								/>
							)}
						</div>
					</Col>

					<Col
						className='my-2'
						xl={3}
						lg={6}
						mid={12}
						sm={12}
						xs={12}
						style={{
							width:
								width > 1600
									? "25%"
									: width < 1600 && width > 1200
										? "33%"
										: largeMid || mid
											? "50%"
											: "100%",
						}}
					>
						<div
							className='card card-custom mx-auto'
							ref={setRef(4)}
							onClick={handleClick}
							id='5'
							style={customCardStyle}
						>
							<div className='card-icon d-flex' >
								<img className="mx-auto my-auto" src={ai} width={mobile ? "60px" : "82px"} height={mobile ? "60px" : "80px"} />
							</div>
							<div className='card-title my-2'>
								<h1 style={h1Font}>{t('homeServicesTech5Title')}</h1>
							</div>
							<div className='card-content' style={cardContentStyle}>
								<h2 style={h2Font}>
									{t('homeServicesTech5Content')}
								</h2>
							</div>
							{selected === 5 && (
								<MoreBtn
									className='mx-auto my-auto'
									child={t('moreButton')}
									onclick={(e) =>
										goTo(e, "artificial-intelligence")
									}
								/>
							)}
						</div>
					</Col>
					<Col
						className='my-2'
						xl={3}
						lg={6}
						mid={12}
						sm={12}
						xs={12}
						style={{
							width:
								width > 1600
									? "25%"
									: width < 1600 && width > 1200
										? "33%"
										: largeMid || mid
											? "50%"
											: "100%",
						}}
					>
						<div
							className='card card-custom mx-auto'
							ref={setRef(5)}
							onClick={handleClick}
							id='6'
							style={customCardStyle}
						>
							<div className='card-icon d-flex' >
								<img className="mx-auto my-auto" src={devOps} width={mobile ? "60px" : "82px"} height={mobile ? "60px" : "80px"} />
							</div>
							<div className='card-title my-2'>
								<h1 style={h1Font}>
									{t('homeServicesTech6Title').split(' ')[0]} <br /> {t('homeServicesTech6Title').split(' ')[1]}
								</h1>
							</div>
							<div className='card-content' style={cardContentStyle}>
								<h2 style={h2Font}>
									{t('homeServicesTech6Content')}
								</h2>
							</div>
							{selected === 6 && (
								<MoreBtn
									className='mx-auto my-auto'
									child={t('moreButton')}
									onclick={(e) => goTo(e, "dev-ops-ci-cd")}
								/>
							)}
						</div>
					</Col>
					<Col
						className='my-2'
						xl={3}
						lg={6}
						mid={12}
						sm={12}
						xs={12}
						style={{
							width:
								width > 1600
									? "25%"
									: width < 1600 && width > 1200
										? "33%"
										: largeMid || mid
											? "50%"
											: "100%",
						}}
					>
						<div
							className='card card-custom mx-auto'
							ref={setRef(6)}
							onClick={handleClick}
							id='7'
							style={customCardStyle}
						>
							<div className='card-icon d-flex' >
								<img className="mx-auto my-auto" src={db} width={mobile ? "60px" : "82px"} height={mobile ? "60px" : "80px"} />
							</div>
							<div className='card-title my-2'>
								<h1 style={h1Font}>
									{t('homeServicesTech7Title')}
								</h1>
							</div>
							<div className='card-content' style={cardContentStyle}>
								<h2 style={h2Font}>
									{t('homeServicesTech7Content')}
								</h2>
							</div>
							{selected === 7 && (
								<MoreBtn
									className='mx-auto my-auto'
									child={t('moreButton')}
									onclick={(e) =>
										goTo(e, "database-management")
									}
								/>
							)}
						</div>
					</Col>
					<Col
						className='my-2'
						xl={3}
						lg={6}
						mid={12}
						sm={12}
						xs={12}
						style={{
							width:
								width > 1600
									? "25%"
									: width < 1600 && width > 1200
										? "33%"
										: largeMid || mid
											? "50%"
											: "100%",
						}}
					>
						<div
							className='card card-custom mx-auto '
							ref={setRef(7)}
							onClick={handleClick}
							id='8'
							style={customCardStyle}
						>
							<div className='card-icon d-flex' >
								<img className="mx-auto my-auto" src={modernizasyon} width={mobile ? "60px" : "82px"} height={mobile ? "60px" : "80px"} />
							</div>
							<div className='card-title my-2'>
								<h1 style={h1Font}>
									{t('homeServicesTech8Title').split(' ')[0]} <br /> {t('homeServicesTech8Title').split(' ')[1]}
								</h1>
							</div>
							<div className='card-content' style={cardContentStyle}>
								<h2 style={h2Font}>
									{t('homeServicesTech8Content')}
								</h2>
							</div>
							{selected === 8 && (
								<MoreBtn
									className='mx-auto my-auto'
									child={t('moreButton')}
									onclick={(e) =>
										goTo(e, "modernization-services")
									}
								/>
							)}
						</div>
					</Col>
				</Row>
				{isForMain && (
					<Row className='mt-4' style={{ justifyContent: "center" }}>
						<Col
							className='my-2 mx-4'
							xl={3}
							lg={6}
							mid={12}
							sm={12}
							xs={12}
							style={{
								width: (
									width > 1600
										? "25%"
										: width < 1600 && width > 1200
								)
									? "33%"
									: largeMid || mid
										? "50%"
										: "100%",
							}}
						>
							<div
								className='card card-custom mx-auto'
								ref={setRef(8)}
								onClick={handleClick}
								id='9'
								style={customCardStyle}
							>
								<div className='card-icon d-flex' >
									<img className="mx-auto my-auto" src={BTDanismanligi} width={mobile ? "60px" : "82px"} height={mobile ? "60px" : "80px"} />
								</div>
								<div className='card-title my-2'>
									<h1 style={h1Font}>
										{t('homeServicesTech9Title').split(' ')[0] + " " + t('homeServicesTech9Title').split(' ')[1]} <br /> {t('homeServicesTech9Title').split(' ')[2]}
									</h1>
								</div>
								<div className='card-content' style={cardContentStyle}>
									<h2 style={h2Font}>
										{t('homeServicesTech9Content')}
									</h2>
								</div>
								{selected === 9 && (
									<MoreBtn
										className='mx-auto my-auto'
										child={t('moreButton')}
										onclick={(e) =>
											goTo(
												e,
												"information-technology-consultancy"
											)
										}
									/>
								)}
							</div>
						</Col>
						<Col
							className='my-2 mx-4'
							xl={3}
							lg={6}
							mid={12}
							sm={12}
							xs={12}
							style={{
								width: (
									width > 1600
										? "25%"
										: width < 1600 && width > 1200
								)
									? "33%"
									: largeMid || mid
										? "50%"
										: "100%",
							}}
						>
							<div
								className='card card-custom mx-auto'
								ref={setRef(9)}
								onClick={handleClick}
								id='10'
								style={customCardStyle}
							>
								<div className='card-icon d-flex' >
									<img className="mx-auto my-auto" src={BulutaTasima} width={mobile ? "60px" : "82px"} height={mobile ? "60px" : "80px"} />
								</div>
								<div className='card-title my-2'>
									<h1 style={h1Font}>
										{t('homeServicesTech10Title').split(' ')[0]} <br /> {t('homeServicesTech10Title').split(' ')[1]}
									</h1>
								</div>
								<div className='card-content' style={cardContentStyle}>
									<h2 style={h2Font}>
										{t('homeServicesTech10Content')}
									</h2>
								</div>
								{selected === 10 && (
									<MoreBtn
										className='mx-auto my-auto'
										child={t('moreButton')}
										onclick={(e) =>
											goTo(e, "cloud-migration")
										}
									/>
								)}
							</div>
						</Col>
					</Row>
				)}
				{!isForMain && (
					<div
						style={{
							width: "100%",
							display: "flex",
							justifyContent: "center",
							paddingBottom: "50px"
						}}
					>
						<MoreBtn
							className='mx-auto'
							styles={{ marginTop: "40px" }}
							child={t('allServicesButton')}
							onclick={redirectToMain}
						/>
					</div>
				)}
			</div>
		</section>
	);
}
