import React, { useEffect } from "react";
import "./App.css";
import Root from "./routes/Root";
import { WindowSizeProvider } from "./context/WindowSizeContext";
import ipLocation from "iplocation";

function App() {

	useEffect(() => {
		const x = async () => {
			const response = await fetch('https://api.ipify.org?format=json');
			const data = await response.json();
			const ip = data.ip;
			console.log(await ipLocation(ip))
		}
		x()
	}, [])


	return (
		<div className='App'>
			<WindowSizeProvider>
				<Root />
			</WindowSizeProvider>
		</div>
	);
}

export default App;
