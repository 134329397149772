import {
	WebGelistirme,
	BTDanismanligi,
	Bulut,
	DevOps,
	GomuluYazilim,
	IoTCozumleri,
	MobilUygulamalar,
	Modernizasyon,
	VeriTabani,
	YZ,
} from "data";

export type IHeader = {
	title: string;
	content: string;

};

export type IOzellikler = {
	o1: {
		title: string;
		content: string;
	};
	o2?: {
		title: string;
		content: string;
	};
	o3?: {
		title: string;
		content: string;
	};
};

export type IHizmetKapsami = {
	i1: {
		st: string;
		sm: string;
	};
	i2?: {
		st: string;
		sm: string;
	};
	i3?: {
		st: string;
		sm: string;
	};
};

export const getHeaderData = (param: string, servicePages: any): IHeader => {
	switch (param) {
		case "web-development":
			return servicePages.webDevelopement.giris;
		case "information-technology-consultancy":
			return servicePages.it.giris;
		case "cloud-migration":
			return servicePages.cloud.giris;
		case "dev-ops-ci-cd":
			return servicePages.devops.giris;
		case "embeded-software-development":
			return servicePages.embeded.giris;
		case "iot-solutions":
			return servicePages.iot.giris;
		case "mobile-applications":
			return servicePages.mobile.giris;
		case "modernization-services":
			return servicePages.modernization.giris;
		case "database-management":
			return servicePages.database.giris;
		case "artificial-intelligence":
			return servicePages.ai.giris;
		default:
			return {
				title: "",
				content: "",

			};
	}
};

export const getOzelliklerData = (param: string, servicePages: any): IOzellikler => {
	switch (param) {
		case "web-development":
			return servicePages.webDevelopement.ozellikler;
		case "information-technology-consultancy":
			return servicePages.it.ozellikler;
		case "cloud-migration":
			return servicePages.cloud.ozellikler;
		case "dev-ops-ci-cd":
			return servicePages.devops.ozellikler;
		case "embeded-software-development":
			return servicePages.embeded.ozellikler;
		case "iot-solutions":
			return servicePages.iot.ozellikler;
		case "mobile-applications":
			return servicePages.mobile.ozellikler;
		case "modernization-services":
			return servicePages.modernization.ozellikler;
		case "database-management":
			return servicePages.database.ozellikler;
		case "artificial-intelligence":
			return servicePages.ai.ozellikler;
		default:
			return {
				o1: {
					title: "",
					content: "",
				},
			};
	}
};

export const getHzimetKapsamiData = (param: string, servicePages: any): IHizmetKapsami => {
	switch (param) {
		case "web-development":
			return servicePages.webDevelopement.hizmet_kapsami;
		case "information-technology-consultancy":
			return servicePages.it.hizmet_kapsami;
		case "cloud-migration":
			return servicePages.cloud.hizmet_kapsami;
		case "dev-ops-ci-cd":
			return servicePages.devops.hizmet_kapsami;
		case "embeded-software-development":
			return servicePages.embeded.hizmet_kapsami;
		case "iot-solutions":
			return servicePages.iot.hizmet_kapsami;
		case "mobile-applications":
			return servicePages.mobile.hizmet_kapsami;
		case "modernization-services":
			return servicePages.modernization.hizmet_kapsami;
		case "database-management":
			return servicePages.database.hizmet_kapsami;
		case "artificial-intelligence":
			return servicePages.ai.hizmet_kapsami;
		default:
			return {
				i1: {
					st: "",
					sm: "",
				},
			};
	}
};
