import React from 'react'
import LeftAligned from '../Projelerimiz/LeftAligned'
//@ts-ignore
import yaklasimimiz from "../../assets/nasilYapiyoruz/yaklasimimiz.png"
//@ts-ignore
import yaklasimMid from "../../assets/nasilYapiyoruz/yaklasimMid.png"
//@ts-ignore
import yaklasimMidEn from "../../assets/nasilYapiyoruz/yaklasimMidEn.png"
//@ts-ignore
import yaklasimMidDe from "../../assets/nasilYapiyoruz/yaklasimMidDe.png"
//@ts-ignore
import yaklasimMidNl from "../../assets/nasilYapiyoruz/yaklasimMidNl.png"

import { Col, Row } from 'react-bootstrap'
import { useWindowContext } from '../../context/WindowSizeContext'
import { useTranslation } from 'react-i18next'




export default function Yaklasimimiz() {

    const { mid, mobile, largeMid, width } = useWindowContext();
    const full = !largeMid && !mid && !mobile;
    const small = mid || mobile;

    const { t, i18n } = useTranslation()

    const titleStyle = {
        fontSize: small ? "20px" : largeMid ? "24px" : "32px",
        paddingRight: small ? "0px" : "100px"
    }

    const contentStyle = {
        fontSize: small ? "14px" : largeMid ? "16px" : "18px",

    }

    const fontStyle = {
        fontSize: small ? "20px" : largeMid ? "25px" : "35px"
    }

    return (
        <div>

            <div style={{ marginTop: "40px" }} />
            <Row>
                {(small || largeMid) && <Col className='col-10' style={{ display: "flex", justifyContent: "center", marginLeft: small ? "40px" : "", marginRight: small ? "40px" : "" }}>
                    <img src={yaklasimimiz} width={full ? 630 : small ? "95%" : 500} height={full ? 530 : small ? 222 : 420} style={{ objectFit: "cover", borderRadius: "10px" }} />
                </Col>}
                <Col className='yaklasim-col' style={{ margin: small ? "50px 20px 80px 40px " : "50px 20px 150px 170px" }}>
                    <p className='left-text-p project-name yaklasim-title' style={titleStyle}>{t('approachTitle')}</p>
                    <span className='left-text-p normal-left-text yaklasim-text' style={contentStyle}>{t('approachContent')}</span>
                </Col>
                {!(small || largeMid) && <Col style={{ display: "flex", justifyContent: "left" }} >
                    <div style={{ width: "80%", height: full ? "80%" : "60%", marginTop: "50px" }}>
                        <img src={yaklasimimiz} width={"100%"} height={"100%"} style={{ objectFit: "cover", borderRadius: "10px" }} />
                    </div>
                </Col>}
            </Row>
            <div className='yaklasim-mid' style={{ display: "flex", alignItems: "center", justifyContent: "space-around", height: small ? "600px" : "800px" }}>
                <Row style={{ marginLeft: !small ? "150px" : "", marginRight: !small ? "150px" : "", position: "relative", display: "flex", alignItems: "center" }}>
                    {small ? (
                        <Col className='yaklasim-mid-img-col col-12' style={{
                            display: "flex",
                            justifyContent: full ? "right" : "center",
                            marginRight: small ? "auto" : "100px",
                            marginLeft: small ? "auto" : "",
                            marginTop: small ? "20px" : 0
                        }}>
                            <img src={i18n.language === "tr" ? yaklasimMid : i18n.language === "eng" ? yaklasimMidEn : i18n.language === "de" ? yaklasimMidDe : yaklasimMidNl} width={full ? "636px" : small ? "292px" : "400px"} height={full ? "630px" : small ? "294px" : "400px"} />
                        </Col>
                    ) : (
                        <Col className={'yaklasim-mid-img-col'} style={{
                            display: "flex",
                            justifyContent: full ? "right" : "center",
                            marginRight: small ? "auto" : "100px",
                            marginLeft: small ? "auto" : "",

                        }}>
                            <img src={i18n.language === "tr" ? yaklasimMid : i18n.language === "eng" ? yaklasimMidEn : i18n.language === "de" ? yaklasimMidDe : yaklasimMidNl} width={full ? "90%" : small ? "292px" : "400px"} height={full ? "90%" : small ? "294px" : "400px"} />
                        </Col>
                    )}

                    <Col className='yaklasim-mid-other-col' style={{
                        marginRight: small ? "auto" : "100px",
                        marginLeft: small ? "auto" : ""
                    }}>
                        {i18n.language === "tr" ? (
                            <p className='yaklasim-mid-p' style={{ padding: small ? "30px 40px 30px 40px" : "", width: small ? "100%" : "90%", ...fontStyle }}>Neokod'da <span>yaratıcılığı, inovasyonu</span> ve <span>kaliteyi</span> ön planda tutarak, yazılım sektöründe fark yaratıyoruz.</p>

                        ) : i18n.language === "eng" ? (
                            <p className='yaklasim-mid-p' style={{ padding: small ? "30px 40px 30px 40px" : "", width: small ? "100%" : "90%", ...fontStyle }}>At Neokod, we make a difference in the software industry by prioritizing <span>creativity, innovation</span> and <span>quality.</span></p>

                        ) : i18n.language === "de" ? (
                            <p className='yaklasim-mid-p' style={{ padding: small ? "30px 40px 30px 40px" : "", width: small ? "100%" : "90%", ...fontStyle }}>Bei Neokod setzen wir <span>Kreativität, Innovation</span> und <span>Qualität</span> in den Vordergrund und machen einen Unterschied in der Softwarebranche.</p>

                        ) : (
                            <p className='yaklasim-mid-p' style={{ padding: small ? "30px 40px 30px 40px" : "", width: small ? "100%" : "90%", ...fontStyle }}>Bij Neokod maken we een verschil in de software-industrie door <span>creativiteit, innovatie</span> en <span>kwaliteit</span> voorop te stellen.</p>
                        )}
                    </Col>
                </Row>
            </div>

        </div>
    )
}
