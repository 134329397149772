import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
//@ts-ignore
import webAlt from "../../assets/webGelistirme/hizmetKapsamı.png"
//@ts-ignore
import btAlt from "../../assets/hizmetler/btAlt.png";
//@ts-ignore
import mobilAlt from "../../assets/hizmetler/mobilAlt.png";
//@ts-ignore
import embededAlt from "../../assets/hizmetler/embededAlt.png";
//@ts-ignore
import devopsAlt from "../../assets/hizmetler/devopsAlt.png";
//@ts-ignore
import bulutAlt from "../../assets/hizmetler/bulutAlt.png";
//@ts-ignore
import dbAlt from "../../assets/hizmetler/dbAlt.png";
//@ts-ignore
import modernizationAlt from "../../assets/hizmetler/modernizationAlt.png";
//@ts-ignore
import iotAlt from "../../assets/hizmetler/iotAlt.png";
//@ts-ignore
import aiAlt from "../../assets/hizmetler/aiAlt.png";
//@ts-ignore
import vector from "../../assets/hizmetler/Vector.png"
import { useLocation } from 'react-router-dom'
import { IHizmetKapsami, getHeaderData, getHzimetKapsamiData } from '../../utils/getContents'
import { useWindowContext } from '../../context/WindowSizeContext';
import { useTranslation } from 'react-i18next';

export default function HizmetKapsamı() {

  const location = useLocation()
  const page = location.pathname

  const { mid, largeMid, mobile } = useWindowContext();
  const full = (!mid && !largeMid && !mobile)
  const small = (mid || mobile)

  const { t, i18n } = useTranslation()

  const projects = t('servicePages', { returnObjects: true });

  const [content, setContent] = useState<IHizmetKapsami>(getHzimetKapsamiData(page.split("/")[2], projects))

  const [altImg, setAltImg] = useState()

  useEffect(() => {
    setContent(getHzimetKapsamiData(page.split("/")[2], projects))

    setAltImg(selectImg(page.split("/")[2]))
  }, [page, i18n.language])

  const selectImg = (page: string) => {
    switch (page) {
      case "web-development":
        return webAlt;
      case "embeded-software-development":
        return embededAlt;
      case "mobile-applications":
        return mobilAlt;
      case "iot-solutions":
        return iotAlt;
      case "artificial-intelligence":
        return aiAlt;

      case "dev-ops-ci-cd":
        return devopsAlt;
      case "database-management":
        return dbAlt;
      case "modernization-services":
        return modernizationAlt;
      case "information-technology-consultancy":
        return btAlt;
      case "cloud-migration":
        return bulutAlt;

      default:
        return "";
    }
  };

  const headerFont = {
    fontSize: small ? "20px" : "35px",
    lineHeight: small ? "40px" : "75px",
  }

  const contentFont = {
    fontSize: small ? "15px" : "18px",
    lineHeight: small ? "30px" : "38px",
  }

  return (
    <div className='hizmet-kapsamı' style={{ padding: small ? "20px" : "100px" }}>
      <Row className='hizmet-kapsamı-row' >
        <Col className='d-flex' xl={6} lg={12} md={12} sm={12} style={{ maxHeight: "644px", marginBottom: (small || largeMid) ? "30px" : "", marginTop: (small || largeMid) ? "30px" : "" }}>
          <img className='mx-auto' src={altImg} width={"70%"} style={{ objectFit: "cover", borderRadius: "10px" }} />
        </Col>
        <Col xl={6} lg={12} md={12} sm={12} style={{ marginLeft: (small) ? "20px" : "", marginRight: (small) ? "20px" : "", width: small ? "90%" : "" }}>
          <p className='hizmet-kapsamı-title' style={headerFont}>{t('scopeOfServices')}</p>
          <span className='hizmet-kapsamı-item' style={contentFont}>
            <strong><img className="me-1" src={vector} />{content.i1.st}</strong>
            {content.i1.sm}
          </span>
          <span className='hizmet-kapsamı-item' style={contentFont}>
            {(content.i2?.st !== "") && (<strong><img className="me-1" src={vector} />{content.i2?.st}</strong>)}

            {content.i2?.sm}
          </span>
          <span className='hizmet-kapsamı-item' style={contentFont}>
            {content.i3?.st !== "" && <strong><img className="me-1" src={vector} />{content.i3?.st}</strong>}
            {content.i3?.sm}
          </span>

        </Col>
      </Row>
    </div>
  )
}
