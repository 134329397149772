import { TFunction } from "i18next"

export const whichNavPageRouteName = (page: string) => {

    switch (page) {
        case "web-development": return 0
        case "embeded-software-development": return 0
        case "mobile-applications": return 0
        case "iot-solutions": return 0
        case "artificial-intelligence": return 0

        case "dev-ops-ci-cd": return 1
        case "database-management": return 1
        case "modernization-services": return 1
        case "information-technology-consultancy": return 1
        case "cloud-migration": return 1
        case "developed-services": return 2
    }
}

export const whichNavPageId = (page: string): number => {

    switch (page) {
        case "web-development": return 0
        case "embeded-software-development": return 1
        case "mobile-applications": return 2
        case "iot-solutions": return 3
        case "artificial-intelligence": return 4

        case "dev-ops-ci-cd": return 5
        case "database-management": return 6
        case "modernization-services": return 7
        case "information-technology-consultancy": return 8
        case "cloud-migration": return 9
        default: return -1
    }
}


export const whichNavPageName = (page: string, t: TFunction<"translation", undefined>): string => {

    switch (page) {
        case "web-development": return t('navServicesTech1')
        case "embeded-software-development": return t('navServicesTech2')
        case "mobile-applications": return t('navServicesTech3')
        case "iot-solutions": return t('navServicesTech4')
        case "artificial-intelligence": return t('navServicesTech5')

        case "dev-ops-ci-cd": return t('navServicesTech6')
        case "database-management": return t('navServicesTech7')
        case "modernization-services": return t('navServicesTech8')
        case "information-technology-consultancy": return t('navServicesTech9')
        case "cloud-migration": return t('navServicesTech10')

        case "developed-services": return t('navDevelopingServices')

        default: return ""
    }
}


export const whichNavPagePhotoUst = (page: string): string => {

    switch (page) {
        case "web-development": return "webGelistirmeGiris"
        case "embeded-software-development": return "embededUst"
        case "mobile-applications": return "mobilUst"
        case "iot-solutions": return "iotUst"
        case "artificial-intelligence": return "aiUst"

        case "dev-ops-ci-cd": return "devopsUst"
        case "database-management": return "dbUst"
        case "modernization-services": return "modernizationUst"
        case "information-technology-consultancy": return "btUst"
        case "cloud-migration": return "bulutUst"

        default: return ""
    }
}




export const whichNavPagePhotoAlt = (page: string): string => {

    switch (page) {
        case "web-development": return "webGelistirmeGiris"
        case "embeded-software-development": return "embededUst"
        case "mobile-applications": return "mobilUst"
        case "iot-solutions": return "IoT Çözümleri"
        case "artificial-intelligence": return "aiUst"

        case "dev-ops-ci-cd": return "DevOps CI/CD"
        case "database-management": return "dbUst"
        case "modernization-services": return "modernizationUst"
        case "information-technology-consultancy": return "btUst"
        case "cloud-migration": return "bulutUst"

        default: return ""
    }
}
