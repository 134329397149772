import React, { useState } from "react";
//@ts-ignore
import line from "../../assets/anasayfa/Rectangle.png";
import "./styles.css";
import MoreBtn from "../../components/MoreBtn";
import CardBtn from "../../components/CardBtn";
//@ts-ignore
import cardBtn from "../../assets/anasayfa/cardBtn.png";
//@ts-ignore
import cardBtnBack from "../../assets/anasayfa/cardBtnBack.png";
//@ts-ignore
import einsan from "../../assets/projelerimiz/einsan.png";
//@ts-ignore
import yetenekKapisi from "../../assets/projelerimiz/yetenekKapisi.png";
//@ts-ignore
import ilacTakip from "../../assets/projelerimiz/ilacTakip.png";
//@ts-ignore
import asistan from "../../assets/projelerimiz/asistan.png";
//@ts-ignore
import kepan from "../../assets/projelerimiz/kepan.png";
import { useNavigate } from "react-router-dom";
import { Projects } from "../../data/Projects";
import { useWindowContext } from "../../context/WindowSizeContext";
import { useTranslation } from "react-i18next";

export default function Projeler() {
	const navigate = useNavigate();

	const { t } = useTranslation()

	const projects = t('projectsHomeContent', { returnObjects: true });

	const { mid, mobile, largeMid, width } = useWindowContext();
	const full = !largeMid && !mid && !mobile;

	const [projectImgs, setProjectImgs] = useState([
		yetenekKapisi,
		einsan,
		ilacTakip,
		asistan,
		kepan,
	]);

	const [whichProject, setWhichProject] = useState(0);

	const handleForwardClick = () => {
		//animate
		if (whichProject === 4) setWhichProject(0);
		else setWhichProject(whichProject + 1);
	};

	const handleBackwardClick = () => {
		//animate
		if (whichProject === 0) setWhichProject(4);
		else {
			setWhichProject(whichProject - 1);
		}
	};

	return full ? (
		<section className='projeler row' style={{ margin: 0, height: "100%", }}>
			<div className='col' style={{ marginTop: "100px" }}>
				<div
					className='heading-container col'
					style={{ paddingTop: "150px", marginLeft: "150px" }}
				>
					<img
						className='mt-auto heading-container-image'
						src={line}
						height={1.5}
						width={70}
					/>
					<h3 className='about-heading' style={{ color: "#fff" }}>
						{t('projects')}
					</h3>
				</div>
				<h4>{projects[whichProject].idStr}</h4>

				<p className='proje-text' style={{ color: "#fff", marginBottom: whichProject === 3 ? "5px" : "" }}>
					{projects[whichProject].title}
				</p>
				<div
					className='card-content'
					style={{
						maxWidth: "675px",
						marginLeft: "240px",
						textAlign: "left",
						paddingLeft: 0,
						paddingTop: whichProject === 3 ? "0px" : ""
					}}
				>
					<p
						className="projects-content"
						style={{
							color: "#fff",
							fontSize: "21px",
							fontWeight: "400",
							lineHeight: "40px",

						}}
					>
						{projects[whichProject].content}
					</p>
				</div>
				<MoreBtn
					styles={{
						marginLeft: "240px",
						marginTop: "40px",
						backgroundColor: "#40B3E7",
					}}
					onclick={() => navigate("/projects")}
					child={t('moreButton')}
				/>
				<div className='projeler-next-prev-btns'>
					<CardBtn
						className='mx-3'
						child={<img src={cardBtnBack} />}
						styles={{
							backgroundColor: "#032749",
							border: "1px solid #40B3E7",
						}}
						onclick={handleBackwardClick}
					/>
					<CardBtn
						className='mx-2'
						child={<img src={cardBtn} />}
						styles={{ backgroundColor: "#40B3E7" }}
						onclick={handleForwardClick}
					/>
				</div>
			</div>

			<div className='col img-col'>
				<img
					src={projectImgs[whichProject]}
					width={"100%"}
					height={"100%"}
				/>
			</div>
		</section>
	) : (
		<section className='projeler-small-screen' style={{ margin: "50px 0 50px 0" }}>
			<div
				className='heading-container col'
				style={{ paddingTop: "30px", marginLeft: "30px" }}
			>
				<img
					className='mt-auto heading-container-image'
					src={line}
					height={1.5}
					width={70}
				/>
				<h3 className='about-heading small-screen'>{t('projects')}</h3>
			</div>
			<div style={{ display: "flex", justifyContent: "center", padding: "20px" }}>
				<img
					src={projectImgs[whichProject]}
					width={"100%"}
					height={"100%"}
				/>
			</div>
			<h4 style={{ marginLeft: "40px", fontSize: (mid || mobile) ? "16px" : "21px" }}>{Projects.projects[whichProject].idStr}</h4>

			<p className='proje-text' style={{ marginLeft: "40px", fontSize: (mid || mobile) ? "24px" : "32px" }}>
				{Projects.projects[whichProject].title}
			</p>
			<div
				className='card-content'
				style={{
					maxWidth: "675px",
					marginLeft: "40px",
					textAlign: "left",
					paddingTop: "10px",
					paddingLeft: "0"
				}}
			>
				<p className="projects-content"
					style={{
						textAlign: "left",
						fontWeight: "400",
						lineHeight: (mid || mobile) ? "30px" : "40px",
						fontSize: (mid || mobile) ? "16px" : "21px"
					}}
				>
					{Projects.projects[whichProject].content}
				</p>
			</div>
			<div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
				<MoreBtn
					styles={{
						marginLeft: "40px",
						width: "150px",
						height: "48px",
						marginTop: "40px",
						backgroundColor: "#40B3E7",
					}}
					onclick={() => navigate("/projects")}
					child={t('moreButton')}
				/>
				<div className='projeler-next-prev-btns'>
					<CardBtn
						className='mx-1'
						child={<img src={cardBtnBack} />}
						styles={{
							backgroundColor: "#032749",
							border: "1px solid #40B3E7",
							marginTop: "42px"
						}}
						onclick={handleBackwardClick}
					/>
					<CardBtn
						className='mx-2'
						child={<img src={cardBtn} />}
						styles={{ backgroundColor: "#40B3E7", marginTop: "42px" }}
						onclick={handleForwardClick}
					/>
				</div>
			</div>

		</section>
	);
}
