import React from 'react'
import ItemHizmet from './ItemHizmet'
//@ts-ignore
import line from "../../assets/webGelistirme/line.png"//@ts-ignore
import { useWindowContext } from '../../context/WindowSizeContext';
import { useTranslation } from 'react-i18next';


export default function ItemArea() {

  const { mid, largeMid, mobile } = useWindowContext();
  const full = (!mid && !largeMid && !mobile)
  const small = mid || mobile

  const { t } = useTranslation()
  const GelistirilenHizmetler: any = t('developedServicesData', { returnObjects: true });

  return (
    <div className='item-area' style={{ padding: small ? "0 30px 80px 30px" : " 30px 150px 150px 150px" }}>
      <div className='d-flex' >
        <img className='mt-4 me-3' src={line} height={1} />
        <p className='area-title' style={{ fontSize: (mid || mobile) ? "20px" : "28px" }}>{t('developedServices')}</p>

      </div>
      {GelistirilenHizmetler.items.map((item, index) => {
        return (
          <ItemHizmet key={item} item={item} />
        )
      })}

    </div>
  )
}
