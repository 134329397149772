import React, { useState } from 'react'
//@ts-ignore
import itemLine from "../../assets/hizmetler/itemLine.png"//@ts-ignore
import hizmet1 from "../../assets/hizmetler/hizmet1.png"
import {Row,Col}from "react-bootstrap"
import { useWindowContext } from '../../context/WindowSizeContext'

interface ItemHizmetProps{
    item: {
        number:string,
        title:string
        content:string
    }
    
}

export default function ItemHizmet(props:ItemHizmetProps) {

    const [isOpen,setIsOpen] = useState(false)

    const { mid, largeMid, mobile } = useWindowContext();
	  const full = (!mid && !largeMid && !mobile)
	  const small = mid||mobile

    const fontStyle1 = {
      fontSize:small?"20px":"26px"
    }
    const fontStyle2 = {
      fontSize:small?"14px":"16px"
    }

  return (
    <div className='item-hizmet' onClick={()=>setIsOpen(!isOpen)} style={{backgroundColor: isOpen ?"rgba(23, 101, 176, 1)":"",padding:isOpen?(small?"20px":"40px 30px 40px 30px"):small?"20px 10px 5px 10px":"40px 30px 5px 30px"}}>
      <p className='item-number' style={{color:isOpen?"#fff":""}}>{props.item.number}</p>
      <Row className='row'>
           <Col lg={isOpen ? 6 : 12}>
                <div className='item-title'>
                    <p style={fontStyle1}>{props.item.title}</p>
                    <p className='open-plus me-4' style={{top: isOpen ? "30px" :small?"10px": "80px"}}>{isOpen ? "-" : "+"}</p> 
                </div>
                {isOpen && <p className='item-content' style={fontStyle2}>{props.item.content}</p>}
           </Col>  
           {isOpen && (
            <Col lg={6} md={12} sm={12} style={{textAlign:"center"}}>
                <img className='mx-auto' src={hizmet1} width={"100%"} />
            </Col>
           )}   
      </Row>
      

      {!isOpen && <img src={itemLine} height={1} width={"100%"}/>}
    </div>
  )
}

