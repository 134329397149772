import React from "react";
import DynamicHeader from "../../components/header/DynamicHeader";
import HizmetlerNavigation from "../../components/HizmetlerNavigation";
import "../HizmetTeck/styles.css";
import ItemArea from "../HizmetTeck/ItemArea";
import Iletisim from "../../components/iletişim/Iletisim";
import Footer from "../../components/footer/Footer";

export default function GelistirilenHizmetlerimiz() {
	return (
		<div className='gelistirilen-hizmetlerimiz'>
			<div style={{ paddingTop: "30px" }} />
			<ItemArea />
		</div>
	);
}
