import React, { useState } from "react";
import DynamicHeader from "../../components/header/DynamicHeader";
import Footer from "../../components/footer/Footer";
//@ts-ignore
import line from "../../assets/anasayfa/Rectangle.png"; //@ts-ignore
import bizKimiz from "../../assets/bizKimiz/bizKimiz.png"; //@ts-ignore
import bizKimiz2 from "../../assets/bizKimiz/bizKimiz2.png"; //@ts-ignore
import Rectangle512258 from "../../assets/bizKimiz/Rectangle512258.png"; //@ts-ignore
import vc from "../../assets/bizKimiz/vc.png"; //@ts-ignore
import mc from "../../assets/bizKimiz/mc.png"; //@ts-ignore
import nextart from "../../assets/bizKimiz/referanslar/nextart.png"; //@ts-ignore
import idemania from "../../assets/bizKimiz/referanslar/idemania.png";//@ts-ignore
import cbikoLogo from "../../assets/bizKimiz/referanslar/cbikoLogo.svg";

import "./styles.css";
import { Col, Container, Row } from "react-bootstrap";
import { useWindowContext } from "../../context/WindowSizeContext";
import { useTranslation } from "react-i18next";

export default function BizKimiz() {
	const [active, setActive] = useState("0");

	const { mid, mobile, largeMid, width } = useWindowContext();
	const full = !largeMid && !mid && !mobile;
	const small = mid || mobile;

	const { t } = useTranslation()

	const handleClick = (id: string) => {
		const object = document.getElementById(id);

		if (object) {
			const positionY = object.offsetTop;
			window.scrollTo(0, positionY);
		}
	};
	const refFontSize = {
		fontSize: small ? "24px" : "35px",
	}

	const fontStyle = {
		fontSize: small ? "15px" : "21px",
		lineHeight: small ? "22px" : "33px",
	};
	const navFontSize = {
		fontSize: small ? "15px" : "20px",
		paddingTop: mobile ? "10px" : "20px",
		paddingBottom: mobile ? "10px" : "20px",
	};
	const vmTitleStyle = {
		fontSize: full ? "50px" : largeMid ? "40px" : mid ? "30px" : "20px",
		lineHeight: small ? "24px" : "46px",
	};
	const vmContentStyle = {
		fontSize: full ? "30px" : largeMid ? "25px" : mid ? "20px" : "14px",
		lineHeight: small ? "20px" : "46px",

	}

	return (
		<div className='Biz-kimiz'>
			<DynamicHeader />
			<div
				className='nav-biz-kimiz mx-auto'
				style={{ marginTop: "30px", width: "70%" }}
			>
				<div
					style={{
						display: mobile ? "block" : "flex",
						width: full || largeMid ? "70%" : mid ? "90%" : "98%",
						justifyContent: "space-around",
					}}
				>
					<span
						id='0'
						className='nav-item'
						title='dev-ops-ci-cd'
						onClick={(e) => handleClick("biz-kimiz")}
						style={{ ...navFontSize, display: "block" }}
					>
						{t('aboutUs')}
					</span>
					{mobile && <hr />}
					<span
						id='1'
						className='nav-item'
						onClick={(e) => handleClick("vizyon-misyon")}
						style={{ ...navFontSize, display: "block" }}
					>
						{t('visionTitle')} & {t('missionTitle')}
					</span>
					{/* {mobile && <hr />}
					<span
						id='2'
						className='nav-item'
						onClick={(e) => handleClick("ekibimiz")}
						style={{ ...navFontSize, display: "block" }}
					>
						Ekibimiz
					</span> */}
					{mobile && <hr />}
					<span
						id='3'
						className='nav-item'
						onClick={(e) => handleClick("partnerlerimiz")}
						style={{ ...navFontSize, display: "block" }}
					>
						{t("refferances")}
					</span>
					{mobile && <br />}
				</div>
			</div>
			<section id='biz-kimiz' className='biz-kimiz'>
				<div
					className='heading-container'
					style={{
						marginLeft: small ? "60px" : "200px",
						marginTop: "80px",
						marginBottom: "40px",
						width: "fit-content",
					}}
				>
					<img
						className='mt-auto heading-container-image'
						src={line}
						height={1.5}
						width={70}
					/>
					<h3
						className='about-heading'
						style={{ fontSize: small ? "25px" : "35px" }}
					>
						{t('aboutUs')}
					</h3>
				</div>
				<Container
					style={{
						marginBottom: "100px",
						paddingRight: small ? "20px" : "",
						paddingLeft: small ? "20px" : "",
					}}
				>
					<Row
						className='biz-kimiz-row'
						style={{ marginBottom: "120px" }}
					>
						{small && (
							<Col xl={6} lg={6} md={12} sm={12}>
								<img
									width={"100%"}
									style={{ objectFit: "cover" }}
									src={bizKimiz}
								/>
							</Col>
						)}
						<Col
							className='biz-kimiz-col1 mx-auto'
							xl={6}
							lg={6}
							md={12}
							sm={12}
						>
							<p
								className='biz-kimiz-title'
								style={{
									fontSize: small ? "20px" : "32px",
									lineHeight: small ? "30px" : "46px",
									marginTop: small ? "30px" : "",
								}}
							>
								{t('aboutUsTitle')}
							</p>
							<p
								className='biz-kimiz-text my-4 pt-4 mx-2'
								style={fontStyle}
							>
								{t('aboutUsPageContent1')}

							</p>
							<p
								className='biz-kimiz-text my-4 mx-2'
								style={fontStyle}
							>
								{t('aboutUsPageContent2')}
							</p>
						</Col>
						{!small && (
							<Col xl={6} lg={6} md={12} sm={12}>
								<img
									width={"95%"}
									style={{ objectFit: "cover" }}
									src={bizKimiz}
								/>
							</Col>
						)}
					</Row>

					<Row className='biz-kimiz-row-2'>
						<Col xl={6} lg={6} md={12} sm={12}>
							<img
								src={bizKimiz2}
								width={small ? "100%" : "90%"}
								style={{ objectFit: "cover" }}
							/>
						</Col>
						<Col className='biz-kimiz-col1 mx-auto'>
							<p
								className='biz-kimiz-text my-4 pt-4 mx-3'
								style={fontStyle}
							>
								{t('aboutUsPageContent3')}
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section id='vizyon-misyon' className='vizyon-misyon'>
				<Row
					className='vizon-misyon-row'
					style={{ display: "flex", justifyContent: "space-around" }}
				>
					<div
						className='vm-card v'
						style={{
							marginTop: full ? "40px" : "",
							marginBottom: !full ? "40px" : "",
							width: full
								? "800px"
								: largeMid
									? "600px"
									: mid
										? "400px"
										: "400px",
							height: full
								? "640px"
								: largeMid
									? "480px"
									: mid
										? "320px"
										: "320px",
						}}
					>
						<img
							className='vm-img'
							width={"100%"}
							height={"100%"}
							src={Rectangle512258}
							style={{ objectFit: "cover" }}
						/>
						<div
							className='vm-card-content'
							style={{ padding: small ? "25px" : "100px" }}
						>
							<img
								src={vc}
								width={small ? "40px" : largeMid ? "50px" : ""}
								height={small ? "40px" : largeMid ? "50px" : ""}
							/>
							<p className='v-title' style={vmTitleStyle}>
								{t('visionTitle')}
							</p>
							<p className='v-text' style={vmContentStyle}>
								{t('visionText')}
							</p>
						</div>
					</div>
					<div
						className='vm-card m'
						style={{
							marginTop: full ? "40px" : "",
							width: full
								? "800px"
								: largeMid
									? "600px"
									: mid
										? "400px"
										: "400px",
							height: full
								? "640px"
								: largeMid
									? "480px"
									: mid
										? "320px"
										: "320px",
						}}
					>
						<img
							className='vm-img'
							width={"100%"}
							height={"100%"}
							src={Rectangle512258}
						/>
						<div
							className='vm-card-content'
							style={{ padding: small ? "25px" : "100px" }}
						>
							<img
								src={mc}
								width={small ? "40px" : largeMid ? "50px" : ""}
								height={small ? "40px" : largeMid ? "50px" : ""}
							/>
							<p className='v-title' style={vmTitleStyle}>
								{t('missionTitle')}
							</p>
							<p className='v-text' style={vmContentStyle}>
								{t('missionText')}
							</p>
						</div>
					</div>
				</Row>
			</section>

			{/* <section id='ekibimiz' className='ekibimiz' style={{position:"relative",}}>
				<div className='blue-div' style={{height:full?"680px":"200px",position:"relative"}}>
					{full?(
						<div className='founder-container'>
						<Row>
							<Col className='founder-col' lg={4}>
								<img style={{ left: "0px" }} src={founder} />
							</Col>
							<Col lg={8} className='quote-col'>
								<p className='quote-mark'>"</p>
								<p className='quote-text' style={vmContentStyle}>
									{"You'll win some and lose some but you'll appreciate life every rising sun".toUpperCase()}
								</p>
								<p
									
									className="f-text"
								>
									Hüseyin Hoca
								</p>
								<p className='f-text'>Kurucu</p>

								<div
									className='d-flex'
									style={{ marginLeft: "250px" }}
								>
									<img className='me-3' src={linkedinnLogo} />
									<img className='mx-3' src={tweeterLogo} />
								</div>
							</Col>
						</Row>
					</div>
					):(
						""
					)}
				</div>
				{!full&&(
					<div className="founder-container-small" style={{height:"fit-content"}}>
					<div className="founder-img-small" >
						<img  src={founder} width={"50%"} />
					</div>
						<div style={{paddingTop:"80px",width:"100%",textAlign:"center"}}>
								
								<br/>
								<span className='' style={{...vmContentStyle,marginTop:"",marginLeft:0,textAlign:"center",width:"60%"}}>
									{"You'll win some and lose some but you'll appreciate every rising sun".toUpperCase()}
								</span>
								<br/>
								<span
									
									style={{ marginTop:largeMid? "80px":mid?"30":"20px",marginLeft:0,width:"fit-content",...smallFText }}
								>
									Hüseyin Hoca
								</span>
								<br/>
								<span  style={{marginLeft:0,width:"fit-content",...smallFText,fontSize:"20px"}}>Kurucu</span>

								<div
									className='d-flex'
									style={{ marginLeft: "250px",paddingBottom:"30px" }}
								>
									<img className='me-3' src={linkedinnLogo} />
									<img className='mx-3' src={tweeterLogo} />
								</div>
						</div>
							
				</div>
				)}
				
			</section> */}

			<div style={{ marginTop: "80px" }} />
			<section id='partnerlerimiz' className='partnerlerimiz' style={{ paddingBottom: "100px" }}>
				<div
					className='heading-container'
					style={{
						marginLeft: small ? "40px" : "100px",
						marginTop: "80px",
						paddingTop: "50px",
					}}
				>
					<img
						className='mt-auto heading-container-image'
						src={line}
						height={1.5}
						width={small ? 20 : 70}
					/>
					<h3 className='about-heading' style={{ ...refFontSize, paddingLeft: small ? "25px" : "80px" }}>{t('refferances')}</h3>
				</div>
				<Row className='referans-row' style={{ paddingTop: small ? "" : "80px" }}>


					<Col className='referans-col' lg={2} style={{ height: small ? "120px" : "160px", marginBottom: small ? "0" : "50px", padding: "15px" }}>
						<img src={nextart} />
					</Col>
					<Col className='referans-col' lg={2} style={{ height: small ? "120px" : "160px", marginBottom: small ? "0" : "50px" }}>
						<img src={idemania} />
					</Col>
					<Col className='referans-col cbiko-ref' lg={2} style={{ height: small ? "160px" : "160px", marginBottom: small ? "0" : "50px" }}>

						<img src={cbikoLogo} width={180} height={90} />

					</Col>
				</Row>
			</section>
			<Footer />
		</div>
	);
}
