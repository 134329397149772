import React from 'react'
import DynamicHeader from '../../components/header/DynamicHeader'
//@ts-ignore
import line from "../../assets/anasayfa/Rectangle.png"//@ts-ignore
import cardBtn from "../../assets/anasayfa/cardBtn.png"//@ts-ignore
import blogPhoto1 from "../../assets/anasayfa/blogPhoto1.png"//@ts-ignore
import blogPhoto2 from "../../assets/anasayfa/blogPhoto2.png"//@ts-ignore
import blogPhoto3 from "../../assets/anasayfa/blogPhoto3.png"
import "./styles.css"
import { Col, Row } from 'react-bootstrap'
import CardBtn from '../../components/CardBtn'
import Footer from "../../components/footer/Footer"
import { useNavigate } from 'react-router-dom'
import { useWindowContext } from '../../context/WindowSizeContext'


export default function Blog() {

  const navigate = useNavigate()

  const { mid, mobile, largeMid, width } = useWindowContext();
	const full = !largeMid && !mid && !mobile;
	const small = mid || mobile;
  const isSmall = mid || mobile

  const cardStyle={
    height:(mid||mobile)?"400px": "620px",
	  width: (mid||mobile)?"255px":"480px",
    marginTop: (mid||mobile)?"30px":"0",
  }

  const fontStyle1 = {
    fontSize:(mid||mobile)?"16px":"28px"
  }

  const fontStyle2 = {
    fontSize:(mid||mobile)?"14px":"18px",
    bottom:isSmall?"20px":"20px",
    left:isSmall?"20px":"30px"
  }

  const cardBtnStyle = {
    right: isSmall ? "15px":"35px",
    bottom: isSmall ? "-35px":"0px"

  }

  const cardContentStyle = {
    paddingTop:isSmall?"10px":"40px"
  }


  return (
    <div className='blog'>
        <DynamicHeader/>
        <div className='heading-container' style={{ paddingTop:small?"30px": "50px",paddingBottom:small?"":"",marginLeft:small?"40px":"100px",marginTop:small?"30px":"50px"}} >
            <img className='mt-auto heading-container-image' src={line} height={1.5} width={70}/>
            <h3 className='about-heading' style={{fontSize: mobile ? "25px":"35px",}}>Blog</h3>
        </div>
        <p className='blog-subtitle' style={{ marginLeft:small?"40px":"100px",marginTop:small?"20px":"50px",fontSize: mobile ? "20px":mid?"25px":"35px"}}>Günceli Yakalayan Konular</p>
        <p className='blog-text-p'style={{ marginLeft:small?"40px":"100px",marginTop:small?"0px":"40px",fontSize: mobile ? "16px":mid?"20px":"26px"}}>Neokod’un sektörel ve aktüel konularla ilgili hazırladığı blog yazılarını takip edebiliriz.</p>

        <div className='blog-cards-content' style={{margin:isSmall?"30px 0 50px 0":"80px 120px 150px 160px"}}>
          <Row >
              <Col className='blog-col' xl={4}>
              <div className='card scroll-card mx-4' style={{...cardStyle ,backgroundColor:"#054583"}}>
              <div className='blog-card-header' ><img src={blogPhoto1} width={"100%"} height={"90%"}/></div>
              <div className='card-content' style={cardContentStyle}>
                <span className='blog-text' style={fontStyle1}>Eski Sistemden Buluta: <br/> Göç Stratejileri</span>
                <h6 className='mt-2' style={fontStyle2}>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy</h6>
                <CardBtn  child={(<img src={cardBtn}/>)} onclick={()=>navigate("/blog/123")} styles={{
                  backgroundColor:"#40B3E7",
                  position: "absolute",
                  ...cardBtnStyle}}/>
                <span className='date' style={fontStyle2}>25 Mayıs</span>
              </div>
              </div>
              </Col>
              <Col className='blog-col' xl={4}>
              <div className='card scroll-card mx-4' style={{backgroundColor:"#40B3E7",...cardStyle}}>
                <div className='blog-card-header'  ><img src={blogPhoto2} width={"100%"}/></div>
                  <div className='card-content' style={cardContentStyle}>
                    <span className='blog-text' style={fontStyle1}>Eski Sistemden Buluta: <br/> Göç Stratejileri</span>
                    <h6 className='mt-2'style={fontStyle2}>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy</h6>
                    <CardBtn  child={(<img src={cardBtn}/>)} onclick={()=>navigate("/blog/123")} styles={{...cardBtnStyle,backgroundColor:"#054583",position: "absolute",}}/>
                    <span className='date' style={fontStyle2}>25 Mayıs</span>
                  </div>
                </div>
              </Col>
              <Col className='blog-col' xl={4}>
              <div className='card scroll-card mx-4' style={{backgroundColor:"#032749",...cardStyle}}>
                <div className='blog-card-header'  ><img src={blogPhoto3} width={"100%"}/></div>
                  <div className='card-content' style={cardContentStyle}>
                    <span className='blog-text' style={fontStyle1}>Eski Sistemden Buluta: <br/> Göç Stratejileri</span>
                    <h6 className='mt-2' style={fontStyle2}>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy</h6>
                    <CardBtn  child={(<img src={cardBtn}/>)} onclick={()=>navigate("/blog/123")} styles={{...cardBtnStyle,backgroundColor:"#40B3E7",position: "absolute",}}/>
                    <span className='date' style={fontStyle2}>25 Mayıs</span>
                  </div>
            </div>
              </Col>
          </Row>
          <Row style={{paddingTop:small?"0":"120px"}}>
              <Col className='blog-col' xl={4}>
                  <div className='card scroll-card mx-4' style={{...cardStyle ,backgroundColor:"#054583"}}>
                  <div className='blog-card-header' ><img src={blogPhoto1} width={"100%"} height={"90%"}/></div>
                  <div className='card-content' style={cardContentStyle}>
                    <span className='blog-text' style={fontStyle1}>Eski Sistemden Buluta: <br/> Göç Stratejileri</span>
                    <h6 className='mt-2' style={fontStyle2}>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy</h6>
                    <CardBtn  child={(<img src={cardBtn}/>)} onclick={()=>navigate("/blog/123")} styles={{
                      backgroundColor:"#40B3E7",
                      position: "absolute",
                      ...cardBtnStyle}}/>
                    <span className='date' style={fontStyle2}>25 Mayıs</span>
                  </div>
                  </div>
                  </Col>
                  <Col className='blog-col' xl={4}>
                  <div className='card scroll-card mx-4' style={{backgroundColor:"#40B3E7",...cardStyle}}>
                    <div className='blog-card-header'  ><img src={blogPhoto2} width={"100%"}/></div>
                      <div className='card-content' style={cardContentStyle}>
                        <span className='blog-text' style={fontStyle1}>Eski Sistemden Buluta: <br/> Göç Stratejileri</span>
                        <h6 className='mt-2'style={fontStyle2}>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy</h6>
                        <CardBtn  child={(<img src={cardBtn}/>)} onclick={()=>navigate("/blog/123")} styles={{...cardBtnStyle,backgroundColor:"#054583",position: "absolute",}}/>
                        <span className='date' style={fontStyle2}>25 Mayıs</span>
                      </div>
                    </div>
                  </Col>
                  <Col className='blog-col' xl={4}>
                  <div className='card scroll-card mx-4' style={{backgroundColor:"#032749",...cardStyle}}>
                    <div className='blog-card-header'  ><img src={blogPhoto3} width={"100%"}/></div>
                      <div className='card-content' style={cardContentStyle}>
                        <span className='blog-text' style={fontStyle1}>Eski Sistemden Buluta: <br/> Göç Stratejileri</span>
                        <h6 className='mt-2' style={fontStyle2}>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy</h6>
                        <CardBtn  child={(<img src={cardBtn}/>)} onclick={()=>navigate("/blog/123")} styles={{...cardBtnStyle,backgroundColor:"#40B3E7",position: "absolute",}}/>
                        <span className='date' style={fontStyle2}>25 Mayıs</span>
                      </div>
                </div>
                  </Col>
          </Row>
        </div>
        <Footer/>
    </div>
  )
}
