import React from "react";
import DynamicHeader from "../../components/header/DynamicHeader";
import Adres from "./Adres";
import Form from "./Form";
import Footer from "../../components/footer/Footer";

export default function Iletisim() {
	return (
		<div className='iletisim'>
			<DynamicHeader />
			<Adres />
			{/* <Form /> */}
			<Footer />
		</div>
	);
}
