import React from 'react'
import "./styles.css"
import { useWindowContext } from '../context/WindowSizeContext';

export interface ICardBtn{
    child: React.ReactNode,
    styles?: React.CSSProperties,
    className?: string,
    onclick?: (e:React.MouseEvent<HTMLButtonElement>) => void
}

export default function CardBtn(props:ICardBtn) {
  const { mid, mobile, largeMid, width } = useWindowContext();
	const full = !largeMid && !mid && !mobile;
  return (
    <div>
      
      <button className={props.className+" btn card-btn"} style={{...props.styles,width:(mobile||mid)?"50px":"80px",height:(mobile||mid)?"50px":"80px"}} onClick={props.onclick}>{props.child} </button>
    </div>
  )
}
