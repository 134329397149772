import React, { useState } from "react";
import DynamicHeader from "../../components/header/DynamicHeader";
import Hizmetlerimiz from "../Anasayfa/Hizmetlerimiz";
import Footer from "../../components/footer/Footer";
import HizmetlerNavigation from "../../components/HizmetlerNavigation";
import { useNavigate } from "react-router-dom";
import GelistirilenHizmetlerimiz from "./GelistirilenHizmetlerimiz";
import { useWindowContext } from "../../context/WindowSizeContext";
import { useTranslation } from "react-i18next";

export default function MainHizmetler() {
	const navigate = useNavigate()

	const { mid, mobile, largeMid, width } = useWindowContext();
	const full = !largeMid && !mid && !mobile;
	const small = mid || mobile;

	const { t } = useTranslation()

	const [active, setActive] = useState("0");

	const handleClick = (id: string) => {
		if (id === "1") {
			setActive("1");
			document.getElementById("0")?.classList.remove("active");
			document.getElementById("1")?.classList.add("active");
		} else {
			setActive("0");
			document.getElementById("1")?.classList.remove("active");
			document.getElementById("0")?.classList.add("active");
		}
		navigate("/services");
	};

	return (
		<div>
			<DynamicHeader />
			<div
				style={{
					backgroundColor:
						active === "0" ? "#fff" : "rgba(3, 39, 73, 1)",
				}}
			>
				<div style={{ paddingTop: "30px" }} />
				<div
					className='nav-hizmetler mx-auto'
					style={{
						justifyContent: small ? "space-between" : "space-around",
						marginTop: "30px",
						padding: small ? "0 0px 0 0px" : "",
						width: small ? "90%" : "70%"
					}}
				>
					<>
						<span
							id='0'
							className='nav-item active px-2'
							style={{ color: active === "1" ? "#fff" : "" }}
							title='dev-ops-ci-cd'
							onClick={(e) => handleClick("0")}
						>
							{t('services')}
						</span>
						<span
							id='1'
							className='nav-item px-2'
							title='gomulu-yazilim-gelistirme'
							onClick={(e) => handleClick("1")}
						>
							{t('developedServices')}
						</span>
					</>
				</div>
				{active === "0" ? (
					<Hizmetlerimiz isForMain />
				) : (
					<GelistirilenHizmetlerimiz />
				)}
			</div>
			<Footer />
		</div>
	);
}
