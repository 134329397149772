import React from 'react'
import DynamicHeader from '../../components/header/DynamicHeader'
//@ts-ignore
import line from "../../assets/anasayfa/Rectangle.png"//@ts-ignore
import blogInner from "../../assets/blog/blogInner.png"//@ts-ignore
import blogInner2 from "../../assets/blog/blogInner2.png"//@ts-ignore
import blogInner3 from "../../assets/blog/blogInner3.png"//@ts-ignore
import cardBtn from "../../assets/anasayfa/cardBtn.png"//@ts-ignore
import cardBtnBack from "../../assets/anasayfa/cardBtnBack.png"

//@ts-ignore
import blogPhoto1 from "../../assets/anasayfa/blogPhoto1.png"
//@ts-ignore
import blogPhoto2 from "../../assets/anasayfa/blogPhoto2.png"
//@ts-ignore
import blogPhoto3 from "../../assets/anasayfa/blogPhoto3.png"


import { Col, Row } from 'react-bootstrap'
import Footer from '../../components/footer/Footer'
import CardBtn from '../../components/CardBtn'
import { useWindowContext } from '../../context/WindowSizeContext'
import { useNavigate } from 'react-router-dom'


export default function InnerBlog() {

    const navigate = useNavigate()
    
    const { mid, mobile, largeMid, width } = useWindowContext();
	const full = !largeMid && !mid && !mobile;

  const isSmall = mid || mobile

    const cardStyle={
        height:(mid||mobile)?"400px": "620px",
          width: (mid||mobile)?"255px":"480px",
        marginTop: (mid||mobile)?"30px":"0",
      }
    
      const fontStyle1 = {
        fontSize:(mid||mobile)?"16px":"28px"
      }
    
      const fontStyle2 = {
        fontSize:(mid||mobile)?"14px":"18px",
        bottom:isSmall?"20px":"20px",
        left:isSmall?"20px":"30px"
      }
    
      const cardBtnStyle = {
        right: isSmall ? "15px":"35px",
        bottom: isSmall ? "-35px":"0px"
    
      }
    
      const cardContentStyle = {
        paddingTop:isSmall?"10px":"40px"
      }
  return (
    <div>
      <DynamicHeader/>
        <div className='heading-container' style={{ paddingTop:isSmall?"30px": "50px",paddingBottom:isSmall?"":"",marginLeft:isSmall?"40px":"100px",marginTop:isSmall?"30px":"50px"}}>
            <img className='mt-auto heading-container-image' src={line} height={1.5} width={70}/>
            <h3 className='about-heading' style={{fontSize: mobile ? "25px":"35px"}}>Eski Sistemden Buluta: <br/> Göç Stratejileri</h3>
        </div>
        <div className='inner-blog-container' style={{padding:isSmall?"30px": "50px 180px 50px 180px"}}>
           <div >
                <img src={blogInner}  width={"100%"} style={{marginBottom:"50px",borderRadius:"10px"}}/>
            </div> 
            <p className='inner-blog-date'>25 Mayıs</p>
            <div className='vertical-line-1'/>
            <p className='inner-blog-text' style={{fontSize:isSmall?"14px":"18px",lineHeight:isSmall?"22px":"27px"}}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl a, faucibus posuere urna, massa, id diam. Cursus viverra bibendum molestie pretium elementum. Sit quam pellentesque felis turpis nisi semper tempor pulvinar pulvinar. Fusce quis duis purus nisi, tristique tempus aliquet. Ridiculus consectetur ac cras amet.
            <br/>
            <br/>
            Urna tristique eget in malesuada a amet, pellentesque urna, lacus. Dignissim viverra turpis nunc suscipit gravida quis potenti sagittis. Eget mauris mi egestas in vitae, amet morbi ac. Scelerisque quis semper habitasse vitae posuere nibh enim, eget. Placerat fermentum leo, suspendisse fringilla enim purus nunc, nulla curabitur. Eget purus enim, gravida sagittis. Et sed feugiat lectus id amet, volutpat. Gravida ut mauris eu nisl amet. Quam euismod molestie sed odio at semper placerat in eu. Id a sit ac orci egestas.
            <br/>
            <br/>
            Nunc quis quisque egestas nunc porttitor mi, quam. Fames elementum proin et placerat porta id arcu in. Ut maecenas amet a porttitor commodo libero ut sit. Tincidunt fermentum malesuada amet sit platea sed condimentum. Commodo, et vulputate vulputate ullamcorper purus mi, sem vitae sed. Tincidunt tellus nulla tellus id. Eget nisi elit nam faucibus ut in ultricies lectus sed. Viverra diam mattis at sed arcu, eleifend pellentesque iaculis. Est, dis nunc eget vitae est cras luctus duis. Erat viverra lacus odio nunc, mattis amet amet, fermentum, suspendisse. Lacus, tristique quam ut dui praesent dapibus aliquet. Maecenas sem cras convallis euismod magnis sodales at. Etiam maecenas fusce ac vulputate iaculis quis ornare.
            <br/>
            <br/>
            Feugiat in turpis porta platea aliquet dictumst aliquam bibendum arcu. Nulla molestie id non vitae. Molestie neque odio a hendrerit in nulla. Adipiscing risus posuere morbi ullamcorper feugiat maecenas. Nisl imperdiet vestibulum, pellentesque consequat, tempor non euismod tortor vestibulum. Tortor, tellus varius auctor massa sed mauris curabitur. Convallis id purus metus, sit nunc, tempus purus a. Erat nunc, senectus adipiscing enim id id consequat. Sodales ullamcorper praesent duis varius euismod amet, nisl leo. Tellus sed tellus imperdiet molestie elementum.
            <br/>
            <br/>
            Lacus ultrices in nunc, sit et. Eleifend cras tellus, vehicula id egestas sit congue. Pellentesque ut tempus mi suscipit at sit dictum. Scelerisque convallis vitae, vulputate venenatis. Id tellus iaculis eget mauris, elementum. Vitae nunc et feugiat ornare aliquet velit id dignissim. Ut at lorem mattis aenean quisque ac. A fermentum id aliquet ipsum suscipit morbi volutpat auctor.  
            </p>
            <br/>
        </div>
        <div className='inner-blog-others' style={{padding:isSmall?"40px":""}}>
            <h3 className='' style={{color:"#284075",fontSize: mobile ? "25px":"35px", paddingTop:isSmall?"30px": "50px",paddingBottom:isSmall?"":"",marginLeft:isSmall?"0":"100px",marginTop:isSmall?"0":"50px"}}>Diğer Haberler</h3>
            <div style={{display:"flex",justifyContent:"center",}}>
        <div className='card-container ' style={{
          overflowX: (mid||mobile)?"hidden":"auto",
          whiteSpace: (mid||mobile)?"nowrap":"none",
          display:(mobile)?"block":"flex",
          marginLeft:(mid||mobile)?"0":"0",
          marginTop:(mid||mobile)?"20px":"100px",
          paddingBottom:(mid||mobile)?"80px":"100px"
        }}>
            <div className='card scroll-card mx-4' style={{...cardStyle ,backgroundColor:"#054583"}}>
              <div className='blog-card-header' ><img src={blogPhoto1} width={"100%"} height={"90%"}/></div>
              <div className='card-content' style={cardContentStyle}>
                <span className='blog-text' style={fontStyle1}>Eski Sistemden Buluta: <br/> Göç Stratejileri</span>
                <h6 className='mt-2' style={fontStyle2}>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy</h6>
                <CardBtn  child={(<img src={cardBtn}/>)} onclick={()=>navigate("/blog/123")} styles={{
                  backgroundColor:"#40B3E7",
                  position: "absolute",
                  ...cardBtnStyle}}/>
                <span className='date' style={fontStyle2}>25 Mayıs</span>
              </div>
              
            </div>
            <div className='card scroll-card mx-4' style={{backgroundColor:"#40B3E7",...cardStyle}}>
                <div className='blog-card-header'  ><img src={blogPhoto2} width={"100%"}/></div>
                  <div className='card-content' style={cardContentStyle}>
                    <span className='blog-text' style={fontStyle1}>Eski Sistemden Buluta: <br/> Göç Stratejileri</span>
                    <h6 className='mt-2'style={fontStyle2}>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy</h6>
                    <CardBtn  child={(<img src={cardBtn}/>)} onclick={()=>navigate("/blog/123")} styles={{...cardBtnStyle,backgroundColor:"#054583",position: "absolute",}}/>
                    <span className='date' style={fontStyle2}>25 Mayıs</span>
                  </div>
                </div>
            <div className='card scroll-card mx-4' style={{backgroundColor:"#032749",...cardStyle}}>
                <div className='blog-card-header'  ><img src={blogPhoto3} width={"100%"}/></div>
                  <div className='card-content' style={cardContentStyle}>
                    <span className='blog-text' style={fontStyle1}>Eski Sistemden Buluta: <br/> Göç Stratejileri</span>
                    <h6 className='mt-2' style={fontStyle2}>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy</h6>
                    <CardBtn  child={(<img src={cardBtn}/>)} onclick={()=>navigate("/blog/123")} styles={{...cardBtnStyle,backgroundColor:"#40B3E7",position: "absolute",}}/>
                    <span className='date' style={fontStyle2}>25 Mayıs</span>
                  </div>
            </div>
            {(full || largeMid)?(
              <>
            <div className='card scroll-card mx-4' style={{...cardStyle ,backgroundColor:"#054583"}}>
              <div className='blog-card-header' ><img src={blogPhoto1} width={"100%"} height={"90%"}/></div>
              <div className='card-content' style={cardContentStyle}>
                <span className='blog-text' style={fontStyle1}>Eski Sistemden Buluta: <br/> Göç Stratejileri</span>
                <h6 className='mt-2' style={fontStyle2}>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy</h6>
                <CardBtn  child={(<img src={cardBtn}/>)} onclick={()=>navigate("/blog/123")} styles={{
                  backgroundColor:"#40B3E7",
                  position: "absolute",
                  ...cardBtnStyle}}/>
                <span className='date' style={fontStyle2}>25 Mayıs</span>
              </div>
              
            </div>
            <div className='card scroll-card mx-4' style={{backgroundColor:"#40B3E7",...cardStyle}}>
                <div className='blog-card-header'  ><img src={blogPhoto2} width={"100%"}/></div>
                  <div className='card-content' style={cardContentStyle}>
                    <span className='blog-text' style={fontStyle1}>Eski Sistemden Buluta: <br/> Göç Stratejileri</span>
                    <h6 className='mt-2'style={fontStyle2}>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy</h6>
                    <CardBtn  child={(<img src={cardBtn}/>)} onclick={()=>navigate("/blog/123")} styles={{...cardBtnStyle,backgroundColor:"#054583",position: "absolute",}}/>
                    <span className='date' style={fontStyle2}>25 Mayıs</span>
                  </div>
                </div>
                <div className='card scroll-card mx-4' style={{backgroundColor:"#032749",...cardStyle}}>
                <div className='blog-card-header'  ><img src={blogPhoto3} width={"100%"}/></div>
                  <div className='card-content' style={cardContentStyle}>
                    <span className='blog-text' style={fontStyle1}>Eski Sistemden Buluta: <br/> Göç Stratejileri</span>
                    <h6 className='mt-2' style={fontStyle2}>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy</h6>
                    <CardBtn  child={(<img src={cardBtn}/>)} onclick={()=>navigate("/blog/123")} styles={{...cardBtnStyle,backgroundColor:"#40B3E7",position: "absolute",}}/>
                    <span className='date' style={fontStyle2}>25 Mayıs</span>
                  </div>
            </div>
            
            
              </>
            ):("")}
        </div>
        </div>
        </div>
        <Footer/>
    </div>
  )
}
