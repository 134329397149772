import React, { useEffect } from "react";
//@ts-ignore
import line from "../../assets/anasayfa/Rectangle.png";
//@ts-ignore
import map from "../../assets/iletisim/mahall_map.png";
import "./styles.css";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { WrappedMapsGoogle } from "./Map";
import { useWindowContext } from "../../context/WindowSizeContext";
import { useTranslation } from "react-i18next";

export default function Adres() {
	const navigate = useNavigate();

	const { mid, largeMid, mobile } = useWindowContext();
	const full = (!mid && !largeMid && !mobile)
	const small = mid || mobile

	const { t } = useTranslation()

	useEffect(() => console.log(mid + " " + mobile), [mid, mobile])

	const fontStyle = {
		fontSize: mid || mobile ? "15px" : "21px"
	}

	return (
		<div className='adres' style={{ paddingLeft: (mobile || mid) ? "0" : "80px" }}>
			<div
				className='heading-container'
				style={{ marginLeft: small ? "40px" : "100px", marginTop: small ? "0px" : "80px" }}
			>
				<img
					className='mt-auto heading-container-image'
					src={line}
					height={1.5}
					width={(mid || mobile) ? 30 : 70}
				/>
				<h3 style={{ marginLeft: (mid || mobile) ? "35px" : "75px", fontSize: (mid || mobile) ? "20px" : "28px" }}>{t('contact')}</h3>
			</div>
			<div className='adres-component' style={{ marginTop: small ? "20px" : "60px" }}>
				<Row className='adres-row' style={{ display: (mobile || mid) ? "block" : "flex" }}>
					{!(mid || mobile) ? "" : (
						<Col className='map-col' onClick={() => navigate("https://maps.app.goo.gl/sEtmNX4d59U6CFV98")}>
							<a href="https://maps.app.goo.gl/sEtmNX4d59U6CFV98">
								<img src={map} width={"90%"} />
							</a>
						</Col>
					)}
					<Col className={'text-col'} style={{ width: "100%", padding: 0 }}>
						<p className='adres-company-name adres-text' style={{ fontSize: (mid || mobile) ? "25px" : "35px" }}>
							{t('firmName')}
						</p>

						<p className='adres-text adres-subtitle  mt-4' style={fontStyle}>{t('address')}</p>
						<p className='adres-text adres-subtitle2 ' style={fontStyle}>
							{t('footerAddressOfficeTitle')}
						</p>
						<a
							className='navigate'
							style={fontStyle}
							href='https://www.google.com/maps/place/Mahall+Ankara/@39.9085279,32.7527288,17.91z/data=!4m6!3m5!1s0x14d3386762728621:0x88fbbb6e491e6502!8m2!3d39.9084058!4d32.7530777!16s%2Fg%2F11b620dfpz?entry=ttu'
						>
							<p className='adres-text mt-1' style={fontStyle}>
								{t('footerAddressOfficeAddress')}
							</p>
						</a>

						<p className='adres-text adres-subtitle2 mt-3' style={fontStyle}>
							{t('footerAddressRnDTitle')}
						</p>
						<a
							className='navigate'

							href='https://www.google.com/maps/place/Gazi+Teknopark+A.+%C5%9E./@39.7798523,32.8059612,17z/data=!3m1!4b1!4m6!3m5!1s0x62a37fa4c47c213b:0x4450c8adbef62e4a!8m2!3d39.7798482!4d32.8085415!16s%2Fg%2F11sbpw2nr4?entry=ttu'
						>
							<p className='adres-text mt-1' style={fontStyle}>
								{t('footerAddressRnDAddress')}
							</p>
						</a>

						<p className='adres-text adres-subtitle mt-3' style={fontStyle}>
							{t('phone')}
						</p>
						<p className='adres-text' style={fontStyle}>+90 539 842 60 07</p>

						<p className='adres-text adres-subtitle mt-3' style={fontStyle}>{t('email')}</p>
						<p className='adres-text' style={fontStyle}>info@neokodyazilim.com</p>
					</Col>
					{(mid || mobile) ? "" : (
						<Col className='map-col col-6' >
							<a href="https://maps.app.goo.gl/sEtmNX4d59U6CFV98">
								<img src={map} />
							</a>
						</Col>
					)}
				</Row>
			</div>
		</div>
	);
}
