import React from "react";
import { Col, Row } from "react-bootstrap";
//@ts-ignore
import vector from "../../assets/hizmetler/Vector.png";
import "./styles.css";
import { useWindowContext } from "../../context/WindowSizeContext";

export interface IProjeContent {
	number: string;
	title: string;
	text: string;
	subtext1?: string;
	subtext2?: string;
	img: string;
}

export default function LeftAligned(props: IProjeContent) {
	const { mid, mobile, largeMid, width } = useWindowContext();
	const full = !largeMid && !mid && !mobile;
	const small = mid || mobile;

	return !small ? (
		<div className='left'>
			<Row>
				<Col style={{ paddingRight: "20px" }}>
					<p className='left-text-p left-number'>{props.number}</p>
					<p className='left-text-p project-name'>{props.title}</p>
					<p className='left-text-p normal-left-text'>{props.text}</p>
					{props.subtext1 && (
						<p className='left-text-p-sub'>
							<img className='mx-2' src={vector} />
							{props.subtext1}
						</p>
					)}
					{props.subtext2 && (
						<p className='left-text-p-sub'>
							<img className='mx-2' src={vector} />
							{props.subtext2}
						</p>
					)}
				</Col>

				<Col className="d-flex" style={{ justifyContent: "center", alignItems: "center" }}>
					<img src={props.img} width={"90%"} />
				</Col>
			</Row>
		</div>
	) : (
		<div className='left' style={{ margin: "50px 0 50px 0" }}>
			<Row>
				<p
					className='left-text-p left-number'
					style={{
						marginLeft: "40px",
						fontSize: mid || mobile ? "16px" : "21px",
						width: "80%",
					}}
				>
					{props.number}
				</p>
				<p
					className='left-text-p project-name'
					style={{
						marginLeft: "40px",
						fontSize: mid || mobile ? "24px" : "32px",
						width: "80%",
					}}
				>
					{props.title}
				</p>
				<div
					style={{
						maxWidth: "675px",
						marginLeft: small ? "40px" : "",
						textAlign: "center",
						paddingTop: "10px",
						paddingLeft: "0",
						width: "85%",
					}}
				>
					<p
						className='left-text-p normal-left-text'
						style={{
							textAlign: "left",
							fontWeight: "400",
							lineHeight: mid || mobile ? "30px" : "40px",
							fontSize: mid || mobile ? "16px" : "21px",
						}}
					>
						{props.text}
					</p>
				</div>
				<div style={{ paddingLeft: "30px", paddingRight: "20px" }}>
					{props.subtext1 && (
						<p
							className='left-text-p-sub'
							style={{
								fontSize: mid || mobile ? "16px" : "21px",
								lineHeight: mid || mobile ? "30px" : "40px",
							}}
						>
							<img className='mx-2' src={vector} />
							{props.subtext1}
						</p>
					)}
					{props.subtext2 && (
						<p
							className='left-text-p-sub'
							style={{
								fontSize: mid || mobile ? "16px" : "21px",
								lineHeight: mid || mobile ? "30px" : "40px",
							}}
						>
							<img className='mx-2' src={vector} />
							{props.subtext2}
						</p>
					)}
				</div>

				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						padding: "20px",
					}}
				>
					<img src={props.img} width={"100%"} height={"100%"} />
				</div>
			</Row>
		</div>
	);
}

/**
 *
 */
