import React, { useEffect, useState } from 'react'
import "./styles.css"
import Navbar from './Navbar'
import { Col, Row } from 'react-bootstrap'
//@ts-ignore
import stick from "../../assets/anasayfa/headerStick.png"
//@ts-ignore
import bg2 from "../../assets/backgroundImages/bg2.png"
//@ts-ignore
import bgPhotoComputer from '../../assets/backgroundImages/bgPhotoComputer.png';

import MoreBtn from '../MoreBtn'
import { useWindowContext } from '../../context/WindowSizeContext'
import TestNavbar from './Navbar'
import { useTranslation } from "react-i18next"

export default function HomeHeader() {

  const { mid, mobile, largeMid } = useWindowContext()

  const full = (!mobile && !mid && !mobile)

  const [isCollapseOpen, setIsCollapseOpen] = useState(false)

  const { t } = useTranslation()

  const bgImgStyle = {
    backgroundImage: ((mobile || mid) && isCollapseOpen) ? '' : `url(${bgPhotoComputer})`
  }


  return (
    <section id='heading'>

      <div className='home-header-component' style={{ ...bgImgStyle, backgroundColor: ((mobile || mid) && isCollapseOpen) ? "#fff" : "rgba(0, 22, 43, 0.06)" }}>
        <img className={((mid || mobile) && isCollapseOpen) ? "no-bg" : "bg"} src={((mid || mobile) && isCollapseOpen) ? "" : bg2} style={{ position: ((mid || mobile) && isCollapseOpen) ? "relative" : "absolute" }} />
        <div className='heading-content' >
          {/* <Navbar/> */}
          <TestNavbar setIsOpen={(x) => setIsCollapseOpen(x)} />
          <div className='intro' >
            {!((mobile || mid) && isCollapseOpen) && (

              <Row>
                <Col lg={9} md={12} sm={12} xs={12} className='my-3' >
                  <div className='header-text-container d-flex mx-auto'>
                    <img className='ms-2 my-auto' src={stick} height={243} />
                    <div className='ms-4'>
                      <p className='intro-text' style={{ color: "rgb(64, 179, 231)", fontSize: full ? "75px" : largeMid ? "50px" : "25px", lineHeight: full ? "88px" : largeMid ? "60px" : "44px" }}>{t('homeTitle1')}</p>
                      <p className='intro-text' style={{ flexWrap: "nowrap", color: "#fff", fontSize: full ? "75px" : largeMid ? "50px" : "25px", lineHeight: full ? "88px" : largeMid ? "60px" : "44px" }}>{t('homeTitle2')}</p>
                    </div>
                  </div>


                </Col>
                <Col lg={3} md={12} sm={12} xs={12} />
              </Row>

            )}
          </div>
        </div>

        {/* <MoreBtn child="Daha Fazla" styles={{marginLeft: "40px", marginTop: "16px"}}/>   */}
      </div>

    </section>


  )
}
//neokod_website/src/assets/backgroundImages/bg2.png