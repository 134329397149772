import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes} from 'react-router-dom'
import Anasayfa from '../pages/Anasayfa/Anasayfa'
import HizmetTechPages from '../pages/HizmetTeck/ButunHizmetSafalari'
import MainHizmetler from '../pages/MainHizmetler/MainHizmetler'
import Blog from '../pages/Blog/Blog'
import Projelerimiz from '../pages/Projelerimiz/Projelerimiz'
import NasilYapiyoruz from '../pages/NasilYapiyoruz/NasilYapiyoruz'
import BizKimiz from '../pages/BizKimiz/BizKimiz'
import IletisimPage from '../pages/Iletisim/IletisimPage'
import InnerBlog from '../pages/Blog/InnerBlog'
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Sayfanın en üstüne kaydır
  }, [pathname]);

  return null;
};

export {ScrollToTop}


export default function Root() {

  return (
    <BrowserRouter>
        <ScrollToTop/>
        <Routes>
            <Route path="/" element={<Anasayfa />} />
            
            <Route path='services' element={<MainHizmetler/>}/>

            <Route path='/services' >
                <Route path='web-development' element={<HizmetTechPages/>}/> 
                <Route path='embeded-software-development' element={<HizmetTechPages/>} />
                <Route path='mobile-applications' element={<HizmetTechPages/>} />
                <Route path='iot-solutions' element={<HizmetTechPages/>} />
                <Route path='artificial-intelligence' element={<HizmetTechPages/>} />

                <Route path='dev-ops-ci-cd' element={<HizmetTechPages/>}/> 
                <Route path='database-management' element={<HizmetTechPages/>} />
                <Route path='modernization-services' element={<HizmetTechPages/>} />
                <Route path='information-technology-consultancy' element={<HizmetTechPages/>} />
                <Route path='cloud-migration' element={<HizmetTechPages/>} />

                
            </Route>
          
            <Route path='/projects' element={<Projelerimiz/>}/>
            <Route path='/how-we-do' element={<NasilYapiyoruz/>}/>
            <Route path='/about-us' element={<BizKimiz/>}/>
            <Route path='/blog' element={<Blog/>}/>
            <Route path='/blog/:blogId' element={<InnerBlog/>} />
            <Route path='/contact' element={<IletisimPage/>}/>

        </Routes>
        </BrowserRouter>
  )
}
