import React from 'react'
import { Col, Row } from 'react-bootstrap'
//@ts-ignore
import vector from "../../assets/hizmetler/Vector.png"
import "./styles.css"

export interface IProjeContent {
    number: string,
    title: string,
    text: string,
    subtext1?: string,
    subtext2?: string,
    img: string
}

export default function RightAligned(props: IProjeContent) {
    return (
        <div className='right'>
            <Row>
                <Col className='d-flex' style={{ justifyContent: "center", alignItems: "center" }}>
                    <img src={props.img} width={"95%"} />

                </Col>

                <Col className='mx-4' style={{ paddingLeft: "30px", textAlign: "left" }}>
                    <p className='left-text-p left-number'>{props.number}</p>
                    <p className='left-text-p project-name'>{props.title}</p>
                    <p className='left-text-p normal-left-text'>{props.text}</p>
                    {props.subtext1 && <p className='left-text-p left-text-p-sub'><img className='mx-2' src={vector} />{props.subtext1}</p>}
                    {props.subtext2 && <p className='left-text-p left-text-p-sub'><img className='mx-2' src={vector} />{props.subtext2}</p>}
                </Col>

            </Row>
        </div>
    )
}
