import React, { useState } from 'react'
import DynamicHeader from '../../components/header/DynamicHeader'
import { useLocation, useNavigate } from 'react-router-dom'
//@ts-ignore
import line from "../../assets/anasayfa/Rectangle.png"//@ts-ignore
import nasilPhoto from "../../assets/nasilYapiyoruz/nasilPhoto.png"//@ts-ignore
import nasilPhotoSmall from "../../assets/nasilYapiyoruz/nasilPhotoSmall.png"
import Yaklasimimiz from './Yaklasimimiz'
import "./styles.css"
import { Col, Row } from 'react-bootstrap'
import Iletisim from '../../components/iletişim/Iletisim'
import Footer from '../../components/footer/Footer'
import { useWindowContext } from '../../context/WindowSizeContext'
import { useTranslation } from 'react-i18next'

export default function NasilYapiyoruz() {

  const location = useLocation()
  const page = location.pathname

  const navigate = useNavigate()

  const { mid, mobile, largeMid, width } = useWindowContext();
  const full = !largeMid && !mid && !mobile;
  const small = mid || mobile;

  const { t } = useTranslation()

  const [active, setActive] = useState("0")


  const handleClick = (currentTarget: string, id: string) => {

    if (id === "1") {
      setActive("1")
      document.getElementById("0")?.classList.remove("active")
      document.getElementById("1")?.classList.add("active")
    }
    else {
      setActive("0")
      document.getElementById("1")?.classList.remove("active")
      document.getElementById("0")?.classList.add("active")
    }
  }

  const fontStyle = {
    fontSize: small ? "14px" : "20px"
  }

  const introFontStyle = {
    fontSize: small ? "20px" : "32px",
    lineHeight: small ? "34px" : "56px",
    height: small ? "90%" : "50%"
  }

  const p1Style = {
    fontSize: small ? "12px" : "22px",
    lineHeight: small ? "20px" : "36px",
    width: "75%"
  }


  const p2Style = {
    fontSize: small ? "10px" : "17px",
    lineHeight: small ? "14px" : "28px",
    width: "85%"
  }

  return (
    <div style={{ backgroundColor: active === "0" ? "rgba(249, 251, 253, 1)" : "#fff" }}>
      <DynamicHeader />
      <div className='nav-hizmetler mx-auto' style={{ padding: small ? "0 30px 0 30px" : "", justifyContent: small ? "space-between" : "space-around", marginTop: "30px", width: small ? "90%" : "70%" }}>
        <>
          <span id='0' className='nav-item active' style={fontStyle} title="dev-ops-ci-cd" onClick={(e) => handleClick("/projelerimiz", "0")}>{t('approach')}</span>
          <span id='1' className='nav-item' style={fontStyle} title="gomulu-yazilim-gelistirme" onClick={(e) => handleClick("/projelerimiz/case-studies", "1")} >{t('howWeDo')}</span>
        </>
      </div>
      <div className='heading-container' style={{ marginLeft: small ? "40px" : "100px", marginTop: small ? "40px" : "80px" }}>
        <img className='mt-auto heading-container-image' src={line} height={1.5} width={70} />
        <h3 className='about-heading' style={{
          fontSize: small ? "20px" : largeMid ? "25px" : "35px"
        }}>
          {active === "0" ? t('approach') : t('howWeDo')}
        </h3>
      </div>
      {active === "0" ? (
        <div className='yaklasimimiz'>
          <Yaklasimimiz />
        </div>
      ) : (small || largeMid) ? (
        <div className='nasil-yapiyoruz' style={{ marginLeft: small ? "40px" : "180px", marginTop: small ? "30px" : "80px" }}>
          <p className='nasil-intro-text' style={introFontStyle}>{t('howWeDoTitle')}</p>
          <Row style={{ height: "fit-content", paddingBottom: "90px" }}>
            <Col>

              <div style={{ marginTop: "270px" }} />
              <p className='nasil-row-p1' style={p1Style}>{t('howWeDoWritingText1')}</p>
              <p className='nasil-row-p2' style={{ ...p2Style, paddingRight: "10px" }}>{t('howWeDoWritingContent1')}</p>

              <div style={{ marginTop: "150px" }} />
              <p className='nasil-row-p1' style={p1Style}>{t('howWeDoWritingText2')}</p>
              <p className='nasil-row-p2' style={p2Style}>{t('howWeDoWritingContent2')}</p>

            </Col>
            <Col style={{ marginLeft: "50px" }}>
              <div style={{ marginTop: "50px" }} />
              <div style={{ marginLeft: "10px" }}>
                <p className='nasil-row-p1' style={p1Style}>{t('howWeDoWritingText3')}</p>
                <p className='nasil-row-p2' style={p2Style}>{t('howWeDoWritingContent3')}</p>
              </div>

              <div style={{ marginTop: "180px" }} />
              <div style={{ marginLeft: "10px" }}>
                <p className='nasil-row-p1' style={p1Style}>{t('howWeDoWritingText4')}</p>
                <p className='nasil-row-p2' style={p2Style}>{t('howWeDoWritingContent4')}</p>

              </div>

              <div style={{ marginTop: "180px" }} />
              <p className='nasil-row-p1' style={p1Style}>{t('howWeDoWritingText5')}</p>
              <p className='nasil-row-p2' style={p2Style}>{t('howWeDoWritingContent5')}</p>
            </Col>
          </Row>
          {<img className='nasil-photo' height={(width < 450) ? 820 : width < 700 ? 700 : 900} style={{ left: "50%", top: "54%", transform: `translate(-50%,-50%)` }} src={nasilPhotoSmall} />}
        </div>
      ) : (
        <div className='nasil-yapiyoruz' style={{ marginLeft: small ? "40px" : "180px", marginTop: small ? "30px" : "80px" }}>
          <p className='nasil-intro-text' style={introFontStyle}>{t('howWeDoTitle')}</p>
          <Row style={{ height: "1000px" }}>
            <Col >
              <div className='nasil-col'>
                <div style={{ marginTop: "550px" }} />
                <p className='nasil-row-p1'>{t('howWeDoWritingText1')}</p>
                <p className='nasil-row-p2'>{t('howWeDoWritingContent1')}</p>
              </div>
            </Col>
            <Col>
              <div style={{ marginTop: "50px" }} />
              <p className='nasil-row-p1'>{t('howWeDoWritingText2')}</p>
              <p className='nasil-row-p2'>{t('howWeDoWritingContent2')}</p>
            </Col>
            <Col>
              <div style={{ marginTop: "550px" }} />
              <p className='nasil-row-p1'>{t('howWeDoWritingText3')}</p>
              <p className='nasil-row-p2'>{t('howWeDoWritingContent3')}</p>
            </Col>
            <Col>
              <div style={{ marginTop: "50px" }} />
              <p className='nasil-row-p1'>{t('howWeDoWritingText4')}</p>
              <p className='nasil-row-p2'>{t('howWeDoWritingContent4')}</p>
            </Col>
            <Col >
              <div style={{ marginTop: "550px" }} />
              <p className='nasil-row-p1'>{t('howWeDoWritingText5')}</p>
              <p className='nasil-row-p2'>{t('howWeDoWritingContent5')}</p>
            </Col>
          </Row>
          <img className='nasil-photo' src={nasilPhoto} width={"95%"} />
        </div>
      )}
      <Iletisim />
      <Footer />
    </div>
  )
}
