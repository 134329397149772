import React from "react";
import Root from "../../routes/Root";
import HomeHeader from "../../components/header/HomeHeader";
import Iletisim from "../../components/iletişim/Iletisim";
import Footer from "../../components/footer/Footer";
import AboutUs from "./AboutUs";
import Hizmetlerimiz from "./Hizmetlerimiz";
import Projeler from "./Projeler";
import Uzmanlık from "./Uzmanlık";
import Blog from "./Blog";
import { Button } from "react-bootstrap";
import { useWindowContext } from "../../context/WindowSizeContext";

export default function Anasayfa() {

	const {width} = useWindowContext()

	return (
		<div className='anasayfa'>
			{/* <Button style={{position:"fixed",top:"20px",right:"20px",backgroundColor:"aliceblue",color:"#000"}}  disabled>Width: {width}</Button> */}
			<HomeHeader /> 
			<AboutUs /> 
			<Hizmetlerimiz />
			<Projeler />
			<Uzmanlık />
			{/* <Blog /> */}
			<Iletisim />
			<Footer />
		</div>
	);
}
