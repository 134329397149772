import React, { useEffect, useState } from "react";//@ts-ignore
//@ts-ignore
import bgPhotoComputer from '../../assets/backgroundImages/bgPhotoComputer.png';
//@ts-ignore
import line from "../../assets/webGelistirme/line.png";
import { useLocation } from "react-router-dom";
import { whichNavPageName } from "../../utils/whichNavPage.ts";
import { getPageName } from "../../utils/getPageName.ts";
import Navbar from "./Navbar.tsx";
import { useWindowContext } from "../../context/WindowSizeContext.tsx";
import { useTranslation } from "react-i18next";

export default function DynamicHeader() {
	const location = useLocation();
	const pageRoute = location.pathname;

	const { mid, largeMid, mobile } = useWindowContext();
	const full = (!mid && !largeMid && !mobile)
	const small = mid || mobile

	const { t } = useTranslation()

	const [page1, setPage1] = useState("");
	const [page2, setPage2] = useState("");

	const [isCollapseOpen, setIsCollapseOpen] = useState(false);

	const [isDrOpen, setIsDrOpen] = useState(false)

	const handleDrOpen = (val: boolean) => setIsDrOpen(val)

	useEffect(() => {
		if (!isCollapseOpen) setIsDrOpen(false)
	}, [isCollapseOpen])

	// useEffect(()=>{alert(isDrOpen)},[isDrOpen])

	const handleSomething = (c: boolean) => {
		setIsDrOpen(c)
	}

	useEffect(() => {
		setPage1(pageRoute.split("/")[1]);
		setPage2(pageRoute.split("/")[2]);

		console.log(pageRoute.split("/"));
		// console.log(pageRoute.split("/")[1])
		console.log(page1);
	}, [pageRoute])

	const bgImgStyle = {
		backgroundImage: ((mobile || mid) && isCollapseOpen) ? '' : `url(${bgPhotoComputer})`
	}
	const fontStyle = {
		fontSize: full ? "45px" : (mid || mobile) ? "20px" : "35px"
	}
	return (
		<section className='dynamic-header' style={{ ...bgImgStyle, backgroundColor: ((mobile || mid) && isCollapseOpen) ? "#fff" : "rgba(0, 30, 58, 0.8)", paddingBottom: (pageRoute.split("/")[1] === "about-us") ? "60px" : small ? (isCollapseOpen) ? "70px" : "" : "" }}>
			<div className="mx-auto" style={{ width: "85%", height: "fit-content" }}>
				<Navbar setIsOpen={(x) => setIsCollapseOpen(x)} setDropdownOpen={(x) => handleDrOpen(x)} />
			</div>
			<div className='bg-div' />
			<div className='header-routes' style={{ marginBottom: isCollapseOpen ? "450px" : "" }}>
				<div
					className='d-flex'
					style={{ marginTop: !(mid || mobile) ? "50px" : "0" }}
				>
					<img className='me-2 my-auto' src={line} height={1.5} />
					<p className='path-text my-auto' style={{ fontSize: full ? "16px" : (mid || mobile) ? "14px" : "14px" }}>
						{t('dynamicNavHome') + " /" +
							(page1 === "services"
								? t('navServices')
								: getPageName(page1, t)) +
							(whichNavPageName(page2, t) === ""
								? ""
								: " / " + whichNavPageName(page2, t))}
					</p>
				</div>
				<span className='page-text ' style={fontStyle}>
					{page1 === "services"
						? t('navServices')
						: getPageName(page1, t)}
				</span>
			</div>
		</section>
	);
}
