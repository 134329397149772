import React, { useEffect, useRef } from "react";
//@ts-ignore
import line from "../../assets/anasayfa/Rectangle.png";
import "./styles.css";
import { useWindowContext } from "../../context/WindowSizeContext";
//@ts-ignore
import small_uzmanik_foto from "../../assets/anasayfa/small_uzmanik_foto.png";
//@ts-ignore
import uzmanlik_large from "../../assets/anasayfa/uzmanlıkBg.png"
//@ts-ignore
import uzmanlık_en from "../../assets/anasayfa/uzmanlık_eng.png"
//@ts-ignore
import uzmanlık_de from "../../assets/anasayfa/uzmanlık_de.png"
//@ts-ignore
import uzmanlık_nl from "../../assets/anasayfa/uzmanlık_nl.png"
//@ts-ignore
import small_uzmanlık_eng from "../../assets/anasayfa/uzmanlık_eng.png"
//@ts-ignore
import small_uzmanlık_de from "../../assets/anasayfa/uzmanlık_de.png"
//@ts-ignore
import small_uzmanlık_nl from "../../assets/anasayfa/uzmanlık_nl.png"

import { useTranslation } from "react-i18next";

export default function Uzmanlık() {

	const { mid, mobile, largeMid, width } = useWindowContext();
	const small = mid || mobile

	const { t, i18n } = useTranslation()


	return (
		<section
			className='uzmanlık container-uzmanlık'
			style={{ backgroundImage: small ? `` : `url(${i18n.language === "tr" ? uzmanlik_large : i18n.language === "eng" ? uzmanlık_en : i18n.language === "de" ? uzmanlık_de : uzmanlık_nl})`, height: small ? "90vh" : "100vh" }}
		>
			<div>
				<div
					className='heading-container col'
					style={{ marginLeft: (mid || mobile) ? "40px" : "150px" }}
				>
					<img
						className='mt-auto heading-container-image'
						src={line}
						height={1.5}
						width={70}
					/>
					<h3 className='about-heading' style={{ fontSize: (mid || mobile) ? "25px" : "35px", marginTop: "30px" }}>{t('expertise')}</h3>
				</div>

				<div className='uzmanlık-text' style={{
					fontSize: (mid || mobile) ? "20px" : "32px",
					marginLeft: (mid || mobile) ? "2rem" : "240px",
					textAlign: (mid || mobile) ? "center" : "unset",
					fontWeight: (mid || mobile) ? "400" : "",
					margin: (mid || mobile) ? "30px 0 0 0" : "50px 200px 0 230px",
					lineHeight: (mid || mobile) ? "30px" : "46px"
				}}>
					<span style={{ marginLeft: "2rem" }}>
						{t('expertiseTitle').split(' ').map((x, i) => i < 4 ? x + " " : "")}
					</span>
					<br />
					<span style={{ marginLeft: "2rem" }}>
						{t('expertiseTitle').split(' ')[4] + " " + t('expertiseTitle').split(' ')[5]}
					</span>


				</div>
				{small && (<img style={{ marginTop: "6rem", maxHeight: "100%", objectFit: "contain" }} src={i18n.language === "tr" ? small_uzmanik_foto : i18n.language === "eng" ? small_uzmanlık_eng : i18n.language === "de" ? small_uzmanlık_de : small_uzmanlık_nl} width={"100%"} />)}
			</div>
		</section>
	);
}
